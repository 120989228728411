import classNames from "classnames";
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
} from "react";
import styles from "./styles.module.css";
import { useAppSelector } from "app/hooks";
import { callError } from "shared/helpers";
import { useTableBodyContext } from "ExcelTable/context";
import { formatter } from "shared/helpers/formatter";

type Props = {
    style?: React.CSSProperties;
    className?: string;
    value?: string;
    isNotAllowedToEdit?: boolean;
    onDoubleClick?: () => void;
    onEnterClick?: () => void;
    onEdit: (val: string) => void;
} & JSX.IntrinsicElements["div"];

const EditableElement = forwardRef((props: Props, ref) => {
    const {
        style,
        className,
        value,
        onDoubleClick,
        isNotAllowedToEdit,
        onEnterClick,
        onEdit,
        ...rest
    } = props;

    function setEditCell() {
        openStatus.current = true;
        if (inputRef.current !== null) {
            const input = inputRef.current as HTMLInputElement;
            input.focus();
        }
    }

    const { fontSize } = useAppSelector((state) => state.Excel);

    const inputRef = useRef(null);
    const openStatus = useRef(false);

    useEffect(() => {
        if (openStatus.current) {
            const input = inputRef.current;
            if (input !== null) {
                (input as HTMLInputElement).focus();
            }
        }
    }, [openStatus.current]);

    const spanValue = useMemo<string>(() => {
        if (!value) return "";
        if (isNaN(+value)) return value;
        return formatter
            .formatToParts(+value)
            .map((part) => (part.type === "decimal" ? "." : part.value))
            .join("");
    }, [value]);

    useImperativeHandle(
        ref,
        () => {
            return {
                setEditCell,
                close: () => (openStatus.current = false),
                openStatus: openStatus.current,
            };
        },
        []
    );

    const { increaseEditableCell, increasePickedCell } = useTableBodyContext();

    return (
        <div
            {...rest}
            style={style}
            className={classNames(styles["editable-element"], className, {
                [styles.uneditable]: isNotAllowedToEdit,
            })}
            onDoubleClick={() => {
                if (!isNotAllowedToEdit) {
                    setEditCell();
                    onDoubleClick?.();
                } else {
                    callError("Данную ячейку невозможно редактировать", 1000);
                }
            }}
            onKeyUp={(e) => {
                if (e.key === "Enter") {
                    onEnterClick?.();
                }
            }}
        >
            <>
                {openStatus.current && (
                    <input
                        value={value}
                        style={{ fontSize }}
                        ref={inputRef}
                        onChange={(e) => onEdit(e.target.value)}
                        onKeyDown={(e) => e.stopPropagation()}
                        onKeyUp={(e) => {
                            if (e.key !== "Enter") e.stopPropagation();
                        }}
                        className={classNames(
                            styles["editable-element__input"],
                            {
                                [styles.hidden]: !openStatus.current,
                            }
                        )}
                    />
                )}
                <pre
                    className={classNames(styles["editable-elemnt__value"], {
                        [styles.none]: openStatus.current,
                    })}
                >
                    {spanValue}
                </pre>
            </>
        </div>
    );
});

export default EditableElement;
