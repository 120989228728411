import { FC, useMemo } from "react";
import { OnePositionSelect } from "entities/PointEvents/components";
import {
    IEventObject,
    IEventPosition,
} from "services/pointEvents/pointEventsSlice";
import { HurricaneSelect, RedLine } from "shared/components";

type Props = {
    isTransition: boolean;
    currentObject: IEventObject | null;
    setCurrentPosition: (val: IEventPosition[]) => void;
    availablePositions: IEventPosition[];
    currentPosition: (IEventPosition | undefined)[];
};

const PeriodPositionSelect: FC<Props> = (props) => {
    const {
        isTransition,
        currentObject,
        setCurrentPosition,
        availablePositions,
        currentPosition,
    } = props;

    const updateCurrentPosition = (val: number, position?: number) => {
        if (currentObject === null || currentPosition.includes(undefined)) return;
        const resultPosition = availablePositions.find(({ Id }) => Id === val);
        if (!resultPosition) return;
        if (position !== undefined) {
            const copy = [...currentPosition];
            copy[position] = resultPosition;
            setCurrentPosition(copy as IEventPosition[]);
            return;
        }
        setCurrentPosition([resultPosition]);
    };

    const parsedPositions = useMemo<{ value: number; label: string }[]>(() => {
        return availablePositions.map((pos) => ({
            value: pos.Id,
            label: `${pos.Position} (${pos.MappedSupplyPointName ?? ""})`,
        }));
    }, [availablePositions]);

    return (
        <div>
            {!isTransition ? (
                <OnePositionSelect
                    disabled={!currentObject?.SupplyPointId}
                    availablePositions={availablePositions}
                    value={currentPosition?.[0]?.Id ?? null}
                    updateCurrentPosition={(val: number) =>
                        updateCurrentPosition(val)
                    }
                />
            ) : (
                <>
                    <div className="pair-wrapper">
                        <div className="pair">
                            с
                            <HurricaneSelect
                                disabled={!currentObject?.SupplyPointId}
                                placeholder="Позиция 1"
                                onChange={(val: number) =>
                                    updateCurrentPosition(val, 0)
                                }
                                value={currentPosition?.[0]?.Id}
                                showSearch
                                options={parsedPositions}
                            />
                        </div>
                        <RedLine />
                        <div className="pair">
                            на
                            <HurricaneSelect
                                disabled={!currentObject?.SupplyPointId}
                                onChange={(val: number) =>
                                    updateCurrentPosition(val, 1)
                                }
                                showSearch
                                value={currentPosition?.[1]?.Id}
                                options={parsedPositions}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default PeriodPositionSelect;
