import { Select } from "antd";
import classNames from "classnames";
import React, { FC } from "react";
import { ISpItem } from "entities/Positions/types";

const { Option } = Select;

type Props = {
    objects: ISpItem[];
    object: ISpItem | null;
    onChangeObject: (value: number | null) => void;
};

const ObjectSelect: FC<Props> = (props) => {
    const { object, onChangeObject, objects } = props;

    return (
        <Select
            className={classNames("editable-select")}
            notFoundContent="Ничего нет. И куда подевалось?"
            placeholder="Выберите объект"
            allowClear
            onClear={() => {
                onChangeObject(null);
            }}
            onChange={onChangeObject}
            value={object?.Id ?? null}
        >
            {objects.length &&
                objects.map((option) => (
                    <Option key={option.Id} value={option.Id}>
                        {option.Name}
                    </Option>
                ))}
        </Select>
    );
};

export default ObjectSelect;
