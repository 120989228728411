import React, { FC } from "react";
import {
    IPeriodModifier,
    IEventPosition,
} from "services/pointEvents/pointEventsSlice";
import {
    changePeriod,
    PeriodWrapper,
    PeriodTransitionWrapper,
} from "../../PeriodWrapper";
import { Input } from "antd";
import { TypesToPeriodDate } from "entities/Types/helpers";
import { IEventType } from "entities/Types/types";

type Props = {
    period: IPeriodModifier;
    index: number;
    currentType: IEventType | null;
    setPeriods: React.Dispatch<React.SetStateAction<IPeriodModifier[]>>;
    beginDate: string;
};

const SimplePeriods: FC<Props> = (props) => {
    const {
        period,
        index: i,
        beginDate,
        setPeriods,
        currentType,
    } = props;

    return (
        <>
            {React.createElement(
                TypesToPeriodDate.includes(
                    currentType?.DraftSupplyPointEventOperationType ?? ""
                )
                    ? PeriodWrapper
                    : PeriodTransitionWrapper,
                { period, setPeriods, beginDate, i }
            )}
            <div className="modificator-container">
                {currentType?.DraftSupplyPointEventOperationType === "DOWN" && (
                    <span>-</span>
                )}
                <Input
                    type="text"
                    value={period?.Value?.[0]}
                    placeholder="Модификатор"
                    onChange={(e) =>
                        changePeriod(
                            setPeriods,
                            beginDate,
                            e.target.value,
                            i,
                            "Value"
                        )
                    }
                />
            </div>
        </>
    );
};

export default SimplePeriods;
