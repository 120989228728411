import { useAppSelector, useActions, useDebounce } from "app/hooks";
import { ConfigProvider, Flex } from "antd";
import React, { FC, useEffect, useMemo, useState, useTransition } from "react";
import { HurricaneCheckbox, SearchInput } from "shared/components";
import FontSizeInput from "../FontSizeInput";
import { useLocation } from "react-router-dom";
import ActionButtons from "../ActionButtons";
import { TableTabItem } from "entities/ExcelTable/types";

type Props = {
    isBottomShowed: boolean;
    toggleBottomShowed: () => void;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    updateTabs: React.Dispatch<React.SetStateAction<TableTabItem[]>>
};

const TableSeracher: FC<Props> = (props) => {
    const { filteredString, showHiddenValues } = useAppSelector(
        (state) => state.Excel
    );
    const location = useLocation();

    const isGuidePage = useMemo(() => {
        return location.pathname.includes("guides");
    }, [location.pathname]);

    // const { currentVersion, updateVersion } = props;
    const { isBottomShowed, toggleBottomShowed, setTrigger, updateTabs } = props;

    const { setFilterValue, changeShowHiddenValuesStatus } = useActions();

    const [data, setData] = useState(filteredString);
    const value = useDebounce<string>(data, 600);
    const [_, startTransition] = useTransition();

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        startTransition(() => setData(e.target.value));
    }

    useEffect(() => {
        if (value !== filteredString) {
            setFilterValue(value);
        }
    }, [value]);

    useEffect(() => {
        return () => {
            setFilterValue("");
        };
    }, []);

    return (
        <ConfigProvider renderEmpty={() => <span>Ничего не найдено...</span>}>
            <Flex align="flex-end" gap={10}>
                <SearchInput
                    className="search"
                    compacted
                    height={55}
                    isLukoil
                    width={150}
                    allowClear
                    style={{ width: 395, height: 55 }}
                    placeholder="Умный поиск"
                    onChange={onChange}
                />

                {!isGuidePage && (
                    <>
                        <HurricaneCheckbox
                            checked={showHiddenValues}
                            style={{ minWidth: 295 }}
                            onChange={() => changeShowHiddenValuesStatus()}
                        >
                            {`${
                                showHiddenValues ? "Скрыть" : "Показать"
                            } скрываемые значения`}
                        </HurricaneCheckbox>{" "}
                        <HurricaneCheckbox
                            checked={isBottomShowed}
                            onChange={() => toggleBottomShowed()}
                        >
                            Сводная таблица
                        </HurricaneCheckbox>
                    </>
                )}

                {/* <HurricaneSelect
                    showSearch
                    optionFilterProp="key"
                    placeholder="Текущие версии"
                    isLukoil
                /> */}

                <FontSizeInput />

                <ActionButtons updateTabs={updateTabs} setTrigger={setTrigger} />
            </Flex>
        </ConfigProvider>
    );
};

export default TableSeracher;
