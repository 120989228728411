import { useState } from "react";


type ReturnType = [string, ( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void, (val: string) =>  void]

export const useInput = (startValue?: string): ReturnType => {
    const [data, setData] = useState<string>(startValue ?? "");

    function onChangeData(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setData(e.target.value);
    }

    function editData(val: string) {
        setData(val);
    }

    return [data, onChangeData, editData];
};
