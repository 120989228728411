import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import classNames from "classnames";
import { ITableColumn } from "entities/ExcelTable/types";
import { Settings } from "lucide-react";
import { SettingsContent } from "entities/ExcelTable/components";
import { useColorSettings } from "ExcelTable/helpers/hooks";
import { useEffectSkipFirstRender } from "hooks";
import { useAppSelector } from "app/hooks";
import ColumnResizer from "react-table-column-resizer";

type Props = {
    column: ITableColumn;
    setAllClose: React.Dispatch<React.SetStateAction<boolean>>;
    onOpenRows: () => void;
    expanded: boolean;
};

const ExcelHeaderNameCell: FC<Props> = ({
    column,
    setAllClose,
    expanded,
    onOpenRows,
}) => {
    const { Name: name } = column;

    const [isOpen, setIsOpen] = useState(false);
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);
    const { colors: stateColors } = useAppSelector((state) => state.Excel);

    const [colors, changeColor, rewriteColors] = useColorSettings();

    useEffectSkipFirstRender(() => {
        if (!isOpen) {
            rewriteColors(stateColors);
        }
    }, isOpen);

    const ref = useRef<HTMLDivElement>(null);

    const draggable = useRef(false);
    const removeDraggable = () => {
        draggable.current = false;
    };
    const onMouseMove = (e: MouseEvent) => {
        if (draggable.current && ref.current) {
            ref.current.style.width = e.clientX - ref.current.getBoundingClientRect().left + 'px';
           
            // updateWidth(e.clientX - ref.current.getBoundingClientRect().left);
        }
    };
    useEffect(() => {
        document.addEventListener("mouseup", removeDraggable);
        document.addEventListener("mousemove", onMouseMove);
        return () => {
            document.removeEventListener("mouseup", removeDraggable);
            document.removeEventListener("mousemove", onMouseMove);
        };
    }, []);

    return (
        <>
            <th
                
                aria-colindex={0}
                className={classNames(styles["excel-name-cell-container"])}
                rowSpan={expanded ? 2 : 1}
            >
                <Settings
                    fontSize={14}
                    width={18}
                    height={18}
                    onClick={() => setIsOpen(true)}
                    className={styles["excel-name-cell__settings"]}
                />

                <SettingsContent
                    colors={colors}
                    changeColor={changeColor}
                    isOpen={isOpen}
                    onClose={closeModal}
                />

                {/* <div
                    className={styles.resizer}
                    onMouseDown={() => (draggable.current = true)}
                /> */}
                <div ref={ref} className={styles["excel-header__cell"]}>
                    <span onClick={onOpenRows} className={styles.plus}>
                        +
                    </span>
                    {name}
                    <span
                        onClick={() => setAllClose((prev) => !prev)}
                        className={styles.minus}
                    >
                        -
                    </span>
                </div>
            </th>
        </>
    );
};

export default ExcelHeaderNameCell;
