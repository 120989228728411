import { FC, useMemo } from "react";
import styles from "./styles.module.css";
import ExcelColumnItem from "./ExcelColumnItem";
import classNames from "classnames";
import { ITableCell, ITableColumn } from "entities/ExcelTable/types";
import { useAppSelector } from "app/hooks";

type Props = {
    value: ITableCell[];
    ariaColindex?: number;
    subColumns?: ITableColumn[];
    dataIndex: string;
    blocked?: boolean;
    isPicked: boolean;
    isSummary: boolean;
    rowId: number;
};

function takeCellItemByColumn(
    data: number,
    value: ITableCell[]
): ITableCell | undefined {
    const current = value.find(({ ColumnIds }) => ColumnIds.includes(data));
    if (!current) return undefined;
    return current;
}

const ExcelColumnCell: FC<Props> = (props) => {
    const {
        value,
        ariaColindex,
        subColumns,
        dataIndex,
        blocked,
        rowId,
        isPicked,
        isSummary,
    } = props;

    const {
        showHiddenValues,
        colors: { column: columnColor, bottom: bottomColumn },
    } = useAppSelector((state) => state.Excel);

    const showedSubColumns = useMemo(() => {
        if (!subColumns) return [];
        if (showHiddenValues) return subColumns;
        return subColumns.filter(({ IsHidden }) => !IsHidden);
    }, [subColumns, showHiddenValues]);

    const actualBackgroundColor = useMemo<string>(
        () => (isSummary ? bottomColumn : columnColor),
        [isSummary]
    );

    if (!showedSubColumns.length)
        return (
            <td
                aria-colindex={ariaColindex}
                data-id={dataIndex}
                className={classNames(styles["excel-td"])}
                style={{
                    backgroundColor: isPicked
                        ? "orange"
                        : actualBackgroundColor,
                }}
            >
                <ExcelColumnItem
                    dataIndex={dataIndex}
                    rowId={rowId}
                    blocked={blocked}
                    value={takeCellItemByColumn(
                        +dataIndex.split(":").at(-1)!,
                        value
                    )}
                />
            </td>
        );
    return (
        <>
            {showedSubColumns.map((column, id) => (
                <td
                    key={column.Id}
                    data-id={`${dataIndex}.${id}`}
                    aria-colindex={ariaColindex}
                    className={classNames(styles["excel-td"])}
                    style={{
                        backgroundColor: isPicked
                            ? "orange"
                            : actualBackgroundColor,
                    }}
                >
                    <ExcelColumnItem
                        key={id}
                        rowId={rowId}
                        dataIndex={`${dataIndex}.${id}`}
                        canHidden={
                            typeof column === "object" && "hidden" in column
                        }
                        blocked={blocked}
                        value={takeCellItemByColumn(column.Id, value)}
                    />
                </td>
            ))}
        </>
    );
};

export default ExcelColumnCell;
