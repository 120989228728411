import React, { createContext, useContext } from "react";
import { IPickedConfig } from "./helpers/hooks/usePickedCells";
import { SortedState } from "entities/ExcelTable/types";
import { CellDirection } from "shared/types";

interface ITableContext {
    allOpen: boolean;
    allClose: boolean;
    currentColumn: React.MutableRefObject<string | number>;
    changeColumnsPositions: (target: HTMLTableCellElement) => void;
    toggleSorting: (field: string) => void;
    hiddenCells: string[];
    toggleHiddenCells: (id: string) => void;
    sorted: SortedState;
}

export const TableContext = createContext<ITableContext | undefined>(undefined);

export const useTableContext = () => {
    const context = useContext(TableContext);
    if (!context) throw new Error("context error");
    return context;
};

interface ITableBodyContext {
    actualEditable: string | null;
    updateEditable: (id?: string) => void;
    pickedCells: IPickedConfig;
    setPickedCell: (ids: { id: string; parentRow: number }[]) => void;
    increaseEditableCell: (direction?: CellDirection) => void;
    increasePickedCell: (e: KeyboardEvent) => void;
    isBottomTableShowed?: boolean;
}

export const TableBodyContext = createContext<ITableBodyContext | undefined>(
    undefined
);

export const useTableBodyContext = () => {
    const context = useContext(TableBodyContext);
    if (!context) throw new Error("context error");
    return context;
};
