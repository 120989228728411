import { FC, useRef, useState, useEffect, useTransition } from "react";
import { TableBodyContext, TableContext } from "ExcelTable/context";
import {
    usePickedCells,
    useColumnPositions,
    useEditableCell,
} from "ExcelTable/helpers/hooks";
import ExcelHeader from "../ExcelHeader";
import ExcelRow from "../ExcelRow";
import styles from "./styles.module.css";
import { useEffectSkipFirstRender } from "hooks";
import { useSortedRows } from "ExcelTable/helpers/hooks/useSortedRows";
import { useMap } from "hooks/useMap";
import { RealTable } from "entities/ExcelTable/types";
import { useActions, useAppSelector } from "app/hooks";
import { DisabledSpace } from "components/UI";
import classNames from "classnames";
import { callError } from "shared/helpers";

const regExp = /^[А-яA-z0-9]{1}$/;

type TableProps = {
    currentTable: RealTable;
    isLoading?: boolean;
    isBottomTableShowed?: boolean;
};

const ExcelTable: FC<TableProps> = (props) => {
    const { currentTable, isLoading, isBottomTableShowed } = props;
    const { title, Id, Rows: data, Columns: columns } = currentTable;

    const ref = useRef<HTMLTableElement>(null);
    const [allOpen, setAllOpen] = useState(false);
    const [_, startTransition] = useTransition();
    const [allClose, setAllClose] = useState(false);

    const { actualEditable, updateEditable, increaseEditableCell } =
        useEditableCell(ref);

    const {
        pickedCells,
        setPickedCell,
        pickedCellsRef,
        increasePickedCell,
        setOperation,
    } = usePickedCells(
        ref,
        () => {
            if (actualEditable !== pickedCells.pickedCells?.[0]?.id) {
                updateEditable();
            }
        },
        actualEditable
    );
    const { togglePointedRows, addEditing } = useActions();

    useEffectSkipFirstRender(() => {
        setAllClose((prev) => !prev);
    }, title);

    useEffectSkipFirstRender(() => {
        if (pickedCells.operation && pickedCells.pickedCells.length > 0) {
            const { id } = pickedCells.pickedCells[0];
            updateEditable(id);
        }
    }, pickedCells.operation);

    function onOpenRows() {
        startTransition(() => {
            setAllOpen((prev) => !prev);
        });
    }

    const onClickNotTable = (e: MouseEvent) => {
        if (!e.target) return setPickedCell([]);
        if (!ref.current?.contains(e.target as Element)) {
            setPickedCell([]);
            updateEditable();
        }
    };

    const onKeyUp = (e: KeyboardEvent) => {
        if (e.altKey) {
            if (e.code === "KeyQ" && pickedCellsRef.current.length > 0) {
                togglePointedRows(pickedCellsRef.current[0].parentRow);
            }
            return;
        }
        increasePickedCell(e);
    };

    const preventKeyDownScrolling = (e: KeyboardEvent) => {
        e.stopPropagation();
        // e.preventDefault();
        if (pickedCellsRef.current.length && e.key.length === 1 && !e.altKey) {
            setOperation(e.key);
        }
    };

    const onExcelPaste = (e: ClipboardEvent) => {
        if (pickedCellsRef.current.length === 0) return;
        e.preventDefault();
        const data = e.clipboardData;
        if (!data) return;
        const pastedData = data
            .getData("text")
            .split("\n")
            .map((el) => el.replace("\r", ""))
            .filter((sub) => sub.length > 0);

        if (pastedData.length === 0) return;
        let array = Array.from(ref.current?.children || []);
        if (!array.length) return;
        array = Array.from(array.at(-1)?.children || []).filter(
            (el) => (el as HTMLTableRowElement).dataset.status !== "none"
        );
        const row = document.querySelector(
            `tr[data-id="${pickedCellsRef.current[0].parentRow}"]`
        ) as HTMLTableRowElement;
        const index = array.indexOf(row);
        const needable = array.slice(index, index + pastedData.length);
        if (needable.length < pastedData.length)
            return callError("Невозможно вставить значение");
        const columnVip = pickedCellsRef.current[0].id.split(":").at(-1);
        const varchik = needable.reduce<[string, string][]>((acc, cur, id) => {
            const element = cur
                .querySelector<HTMLTableCellElement>(
                    `td[data-id$="${columnVip}"]`
                )!
                .querySelector<HTMLDivElement>("[data-column]")!;

            if (element.dataset.none === "true") {
                return acc;
            }
            const key = element.dataset.column;

            acc.push([key ?? "unegern", pastedData[id]]);
            return acc;
        }, []);

        addEditing(varchik);
    };

    // тут хранятся все слушатели событий, в других местах постарался удалить
    useEffect(() => {
        document.addEventListener("click", onClickNotTable);
        document.addEventListener("keyup", onKeyUp);
        document.addEventListener("keydown", preventKeyDownScrolling);
        document.addEventListener("paste", onExcelPaste);

        return () => {
            document.removeEventListener("click", onClickNotTable);
            document.removeEventListener("keyup", onKeyUp);
            document.removeEventListener("keydown", preventKeyDownScrolling);
            document.removeEventListener("paste", onExcelPaste);
        };
    }, []);

    const { currentColumn, sortedColumns, changeColumnsPositions } =
        useColumnPositions(Id, columns);

    const { rows, toggleSorting, sorted } = useSortedRows(data);

    const [hiddenCells, toggleHiddenCells] = useMap<string>();
    const { fontSize, promises, activeIndex } = useAppSelector(
        (state) => state.Excel
    );

    const [heightConfig, setHeightConfig] = useState({
        height: 0,
        isHidden: false,
    });

    const containerRef = useRef(null);
    useEffect(() => {
        if (containerRef.current) {
            const elem = containerRef.current as HTMLElement;

            setHeightConfig({
                ...heightConfig,
                height:
                    document.body.clientHeight -
                    elem.getBoundingClientRect().top -
                    8,
            });
        }
    }, [containerRef]);

    return (
        <DisabledSpace isActive={!!isLoading} zIndex={333}>
            <div
                ref={containerRef}
                style={{
                    maxHeight: heightConfig.height,
                }}
                className={styles["excel-table-container"]}
            >
                {/* {JSON.stringify(pickedCells.pickedCells)}
                <br />
                {JSON.stringify(actualEditable)}
                <br />
                {JSON.stringify(promises)} */}
                <table
                    className={styles["excel-table"]}
                    align="center"
                    cellPadding={0}
                    cellSpacing={0}
                    border={1}
                    ref={ref}
                    // onContextMenu={onPickCell}
                >
                    <TableContext.Provider
                        value={{
                            allClose,
                            allOpen,
                            changeColumnsPositions,
                            currentColumn,
                            hiddenCells,
                            toggleHiddenCells,
                            toggleSorting,
                            sorted,
                        }}
                    >
                        <ExcelHeader
                            columns={sortedColumns}
                            setAllClose={setAllClose}
                            onOpenRows={onOpenRows}
                        />
                        <TableBodyContext.Provider
                            value={{
                                actualEditable,
                                pickedCells,
                                setPickedCell,
                                updateEditable,
                                isBottomTableShowed,
                                increaseEditableCell,
                                increasePickedCell,
                            }}
                        >
                            <tbody
                                style={{ fontSize: fontSize + "px" }}
                                className={classNames({
                                    none: heightConfig.isHidden,
                                })}
                            >
                                {rows.map((row, id) => (
                                    <ExcelRow
                                        key={id}
                                        dataIndex={`row-${row.Id}`}
                                        item={row}
                                        columns={sortedColumns}
                                    />
                                ))}
                            </tbody>
                        </TableBodyContext.Provider>
                    </TableContext.Provider>
                </table>
            </div>
        </DisabledSpace>
    );
};

export default ExcelTable;
