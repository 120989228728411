import React, { FC, useEffect, useState } from "react";
import { useFetch } from "hooks";
import {
    IEventObject,
    IPointEvent,
} from "services/pointEvents/pointEventsSlice";
import { usePositions } from "./hooks";
import { useFirstLoading } from "hooks/useFirstLoading";
import { BigLoader } from "../UI";
import EventModal from "./EventModal";
import { PointEventsContext } from "entities/PointEvents/context/PointEventsContext";
import { IEventType } from "entities/Types/types";
import { callError } from "shared/helpers";
import { getObjects } from "shared/api";

type Props = {
    onClose: () => void;
    currentEvent: IPointEvent | undefined;
    searchPatternFilter: string;
    beginDateFilter?: string;
    endDateFilter?: string;
    supplyPointIdFilter?: number;
    eventSupplyPointMappingIdFilter?: number;
    role: "lineman" | "acceptor";
    availableTypes: IEventType[];
    onAcceptEvent: (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        id: number
    ) => void;
};

const EventModalContainer: FC<Props> = (props) => {
    const { currentEvent, onClose, availableTypes, onAcceptEvent, ...rest } =
        props;

    const { data: availableObjects, isLoading } = useFetch(getObjects);
    const [currentObject, setCurrentObject] = useState<
        IEventObject | null | undefined
    >(undefined);

    const {
        availablePositions,
        setAvailablePositions,
        isLoading: positionsLoading,
    } = usePositions();

    useEffect(() => {
        if (!availableObjects?.length) return;
        if (!currentEvent) {
            setAvailablePositions();
            setCurrentObject(null);
            return;
        }
        const currentObjectLocal = availableObjects?.find(
            (obj) => obj.SupplyPointId === +currentEvent?.SupplyPointId
        );
        // пока костыль
        if (currentObjectLocal === undefined) {
            callError("Не найден текущий объект");
            onClose();
            return;
        }
        setCurrentObject(currentObjectLocal);
        setAvailablePositions(currentObjectLocal?.SupplyPointId);
    }, [availableObjects, currentEvent]);

    const firstLoading = useFirstLoading(positionsLoading || isLoading);

    if (currentObject === undefined || !availableObjects) return null;
    if (firstLoading) return <BigLoader />;
    return (
        <PointEventsContext.Provider
            value={{
                availableTypes,
                onAcceptEvent,
                currentObject,
            }}
        >
            <EventModal
                currentEvent={currentEvent}
                {...rest}
                onClose={onClose}
                availableObjects={availableObjects}
                availablePositions={availablePositions}
                setAvailablePositions={setAvailablePositions}
                currentObject={currentObject}
                setCurrentObject={setCurrentObject}
            />
        </PointEventsContext.Provider>
    );
};

export default EventModalContainer;
