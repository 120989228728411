import { months } from "shared/constants/common";
import { getRates } from "entities/ISGuide/api";
import {
    ICreatingFormResponse,
    resourceDictionary,
    ResourceMode,
} from "entities/ISGuide/types";
import { TabsParams } from "../types";
import { ReactNode } from "react";
import { getReportsArchive } from "entities/ISBalance/api";
import { getDateInterval } from "shared/helpers";
import { BalanceReport } from "entities/ISBalance/types";

export const radioMonthDictionary = months.map((el) => ({
    key: el.name.full,
    value: String(el.value),
}));

export const reportSubFilters = [
    {
        name: "Month",
        itemsInLine: 4,
        items: radioMonthDictionary,
    },
    {
        name: "Year",
        itemsInLine: 4,
        items: [
            { key: "2020", value: "2020" },
            { key: "2021", value: "2021" },
            { key: "2022", value: "2022" },
            { key: "2023", value: "2023" },
            { key: "2024", value: "2024" },
        ],
    },
];

export const getReportItems = (filter?: { [key: string]: string }) => {


    const triggerFilterValue = filter?.settings ?? "";
    const subFilter = reportSubFilters.find(
        ({ name }) => name.toLowerCase() === triggerFilterValue?.toLowerCase()
    );
    return [
        {
            name: "settings",
            items: [
                {
                    key: "Месяц",
                    value: "Month",
                },
                {
                    key: "Год",
                    value: "Year",
                },
            ],
        },
        subFilter ?? reportSubFilters[0],
    ];
};

const columns = (resources: ICreatingFormResponse[]) => [
    {
        title: "Наименование",
        dataIndex: "name",
        render(_: any, record: { StartDate: string; EndDate: string }) {
            return (
                <span>
                    {[record.StartDate, record.EndDate]
                        .map((el) => el.split("-")[0])
                        .join("-")}
                </span>
            );
        },
    },
    {
        title: "Ресурс",
        dataIndex: "EnergySourceId",
        render(_: any, record: { EnergySourceId: number }) {
            const actualResource = resources.find(
                (r) => r.EnergySourceId == record.EnergySourceId
            );
            return (
                <span>{actualResource?.EnergySourceName ?? "Неизвестно"}</span>
            );
        },
    },
    {
        title: "Статус",
        dataIndex: "Mode",
        render(_: any, record: { Mode: ResourceMode }) {
            return <span>{resourceDictionary[record.Mode].full}</span>;
        },
    },

    {
        title: "Действия",
        dataIndex: "actions",
    },
];

const reportColumns = (resources: ICreatingFormResponse[]) => [
    {
        title: "Дата",
        dataIndex: "StartDate",
        render(_: any, record: BalanceReport) {
            return <span>{getDateInterval([record.StartDate, record.EndDate])}</span>;
        },
    },
    {
        title: "Наименование",
        dataIndex: "Name",
        render(_: any, record: BalanceReport) {
            return (
                <span>
                    {record.Name ??
                        `${
                            resources.find(
                                (el) =>
                                    el.EnergySourceId === record.EnergySourceId
                            )?.EnergySourceName
                        } отчет`}
                </span>
            );
        },
    },
    {
        title: "Статус",
        dataIndex: "status",
    },
    {
        title: "Отправ",
        dataIndex: "sended",
    },
    {
        title: "Действия",
        dataIndex: "actions",
    },
];

export const getColumnsApiDictionary: (
    resources: ICreatingFormResponse[]
) => Record<
    TabsParams,
    {
        columns: {
            title: string;
            dataIndex: string;
            render?: (...params: any) => ReactNode;
        }[];
        api: (params?: { [key: string]: string }) => Promise<any[]>;
    }
> = (resources) => ({
    balance: { columns: reportColumns(resources), api: getReportsArchive },
    guide: { columns: columns(resources), api: getRates },
});
