import { Flex, Typography } from "antd";
import { RcFile } from "antd/es/upload";
import { DownloadExcel } from "entities/CMS/components";
import { uploadData } from "entities/ISLoadFiles/api";
import { useState } from "react";
import { CallbackButton, HurricaneFile } from "shared/components";
import { callSuccess } from "shared/helpers";

const LoadFilePage = () => {
    const [fileList, setFileList] = useState<RcFile[]>([]);

    async function sendFile() {
        if (fileList.length > 0) {
            await uploadData(fileList[0]);
            callSuccess("Успешно");
        }
    }

    return (
        <Flex vertical gap={20}>
            <Typography.Title level={1}>
                Загрузка значений файлов Сырья, ТЭР
            </Typography.Title>
            <Flex gap={12} vertical>
                <HurricaneFile
                    fileList={fileList}
                    modifiedFileList={setFileList}
                />

                <CallbackButton disabled={!fileList.length} onClick={sendFile}>
                    Отправить
                </CallbackButton>
            </Flex>

            <DownloadExcel />
        </Flex>
    );
};

export default LoadFilePage;
