import {
    IModifier,
    IPeriodModifier,
} from "services/pointEvents/pointEventsSlice";

export function parseModifierTransition(data: IModifier[]): IPeriodModifier[] {
    let counter = 0;
    const answer: IPeriodModifier[] = [];
    while (counter < data.length) {
        const current = data[counter];
        const next = data[counter + 1];
        const mappindgId = next.CellMappingId as number;
        const result: IPeriodModifier = {
            BeginDate: current.BeginDate,
            EndDate: current.EndDate,
            Value: [current.Value, next.Value],
            Position: [current.Position, next.Position],
            CellMappingId: Array.isArray(current.CellMappingId)
                ? [...current.CellMappingId, mappindgId]
                : [current.CellMappingId, mappindgId],
            id: +`${new Date().getTime()}${counter}`,
        };
        answer.push(result);
        counter += 2;
    }
    return answer;
}
