import { Typography } from "antd";
import { BigLoader } from "components/UI";
import { ResponseTable } from "entities/ExcelTable/types";
import ExcelTable from "ExcelTable/components/ExcelTable";
import { FC } from "react";
import { useAppSelector } from "app/hooks";

type Props = {
    currentTable: ResponseTable | null | undefined;
    title: string;
    isLoading: boolean;
    isBottomTableShowed: boolean;
};


const TableBlock: FC<Props> = (props) => {
    const { currentTable, isLoading, title, isBottomTableShowed } = props;

    const { isLoadingState } = useAppSelector((state) => state.Excel);

    if (currentTable === undefined) return <BigLoader />;
    if (currentTable === null)
        return isLoading ? (
            <BigLoader />
        ) : (
            <Typography.Title level={2}>
                Активный отчет не найден. Выберите существующий в архиве или{" "}
                <br />
                создайте новый!
            </Typography.Title>
        );
    return (
        <div>
            <ExcelTable
                currentTable={{
                    ...currentTable,
                    title,
                }}
                isBottomTableShowed={isBottomTableShowed}
                isLoading={isLoading || isLoadingState}
            />
            {/* <BottomTable items={test} /> */}
        </div>
    );
};

export default TableBlock;
