import React, { FC, useMemo } from "react";
import { IPointEvent } from "services/pointEvents/pointEventsSlice";
import { Toggler } from "shared/components";
import styles from "./styles.module.css";
import { Tooltip } from "antd";

type Props = {
    role: "lineman" | "acceptor";
    point: IPointEvent;
    onAcceptEvent: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
};

const EventStatus: FC<Props> = React.memo((props) => {
    const { role, point, onAcceptEvent } = props;

    const pointStatusColor = useMemo(() => {
        switch (point.IsAccepted) {
            case true:
                return { text: "Событие одобрено", color: "#32B232" };
            case false:
                return { text: "Событие отклонено", color: "#ED1A3B" };
            default:
                return { text: "Событие ожидает одобрения", color: "#efd44f" };
        }
    }, [point]);

    if (role === "acceptor")
        return (
            <Toggler
                onChange={(e) => onAcceptEvent(e, point.Id as number)}
                checked={point?.IsAccepted ?? false}
            />
        );
    return (
        <Tooltip title={pointStatusColor.text}>
            <div
                className={styles["event-status"]}
                style={{ backgroundColor: pointStatusColor.color }}
            />
        </Tooltip>
    );
});

export default EventStatus;
