import { useCallback, useEffect, useMemo, useState } from "react";
import { AddButton } from "../../../components/UI";
import { Form, message, Modal } from "antd";
import { ModalHeader } from "./UI";
import { EditableColumnTypes } from "entities/EditableTable/Types";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { CreatableForm, DefaultModalFooter } from "shared/components";

type Props = {
    title?: string;
    description?: string;
    showed: boolean;
    closeShowed: () => void;
    onAccept: (data: any) => Promise<void>;
    fields: EditableColumnTypes;
    defaultValues?: Record<string, any>;
    buttonTitle?: string;
};

type ParsedFields = {
    localSaving: string[];
    initialValue: Record<string, any>;
    localWritings: string[];
};

const AddItemModal = (props: Props) => {
    const {
        title,
        defaultValues,
        description,
        onAccept,
        showed,
        closeShowed,
        fields,
        buttonTitle,
    } = props;

    useEffect(() => {
        if (!defaultValues) return;
        form.setFieldsValue(defaultValues);
    }, [defaultValues]);

    const parsedFields = useMemo<ParsedFields>(() => {
        return fields.reduce<ParsedFields>(
            (acc: ParsedFields, cur: EditableColumnTypes[0]) => {
                if (cur.inLocalStorageStatus === "read") {
                    acc.localSaving.push(cur.dataIndex);
                }
                if (cur.inLocalStorageStatus === "write") {
                    acc.localWritings.push(cur.dataIndex);
                }
                if (cur.defaultValue !== undefined) {
                    acc.initialValue[cur.dataIndex] = cur.defaultValue;
                }

                return acc;
            },
            { localSaving: [], initialValue: {}, localWritings: [] }
        );
    }, [fields]);

    function onCreate() {
        return form
            .validateFields()
            .then((data: any) => {
                setIsLoading(true);
                onAccept(data);
            })
            .then(() => {
                if (parsedFields.localSaving.length) {
                    parsedFields.localSaving.forEach((key) => {
                        window.localStorage.setItem(
                            key,
                            JSON.stringify(form.getFieldValue(key))
                        );
                    });
                }
                if (parsedFields.localWritings.length) {
                    parsedFields.localWritings.forEach((key) => {
                        const value = window.localStorage.getItem(key);
                        const newValue = [
                            value,
                            JSON.stringify(form.getFieldValue(key)),
                        ]
                            .filter((el) => !!el)
                            .join(" ");
                        window.localStorage.setItem(key, newValue);
                    });
                }
            })
            .then(onClosed)
            .catch((_) => {
                message.error("Проверьте корректность заполненных полей");
            })
            .finally(() => setIsLoading(false));
    }

    const onClosed = useCallback(() => {
        closeShowed();
        form.resetFields();
    }, [closeShowed]);

    useEffect(() => {
        if (!showed) {
            form.resetFields();
        }
        document.body.style.overflowY = showed ? "hidden" : "auto";
    }, [showed]);

    const [form] = Form.useForm();

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const hasDefaultValues = !!Object.keys(parsedFields.initialValue).length;

    return (
        <>
            <Modal
                open={isConfirmationOpen}
                title="Подтвердить закрытие"
                centered
                width={700}
                footer={
                    <DefaultModalFooter
                        onAccept={() => {
                            setIsConfirmationOpen(false);
                            onClosed();
                        }}
                        acceptText="Ок"
                        onReject={() => setIsConfirmationOpen(false)}
                    />
                }
            ></Modal>
            <div
                className={classNames(styles["modal-background"], {
                    [styles.none]: !showed,
                })}
                onClick={() => setIsConfirmationOpen(true)}
            >
                <div
                    className={styles["modal-body"]}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={styles["scroll-container"]}>
                        <ModalHeader
                            title={title}
                            description={description}
                            closeShowed={onClosed}
                        />
                        <CreatableForm
                            initialValue={
                                hasDefaultValues
                                    ? parsedFields.initialValue
                                    : undefined
                            }
                            form={form}
                            fields={fields}
                        />
                    </div>
                    <AddButton
                        disabled={isLoading}
                        className={"modal__button"}
                        onClick={onCreate}
                    >
                        {buttonTitle ?? "Создать"}
                    </AddButton>
                </div>
            </div>
        </>
    );
};

export default AddItemModal;
