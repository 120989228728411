import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IFilter, IFilterFabric } from "shared/types/filters";

export const useFilters = (filters: IFilter[]) => {
    function updateFilters(key: string, value: string | null) {
        if (value === undefined) return;
        const newFilters = { ...filterValue, [key]: value };

        setFilterValue(newFilters);
        const notEmptyValues = Object.fromEntries(
            Object.entries(newFilters).filter(([_, value]) => !!value) as [
                string,
                string | null
            ][]
        ) as Record<PropertyKey, string>;
        setSearchParams(notEmptyValues);
    }

    const [searchParams, setSearchParams] = useSearchParams();

    const [filterValue, setFilterValue] = useState<{
        [key: string]: string | null | string[];
    }>(() => {
        return filters.reduce((acc, cur) => {
            return {
                ...acc,
                [cur.name]:
                    (cur.isMulti
                        ? searchParams.getAll(cur.name)
                        : searchParams.get(cur.name)) ??
                    (cur.type === "Input" ? "" : cur.isMulti ? [] : null),
            };
        }, {});
    });

    return { filter: filterValue, updateFilters };
};
