import { FC, useState, memo, useMemo } from "react";
import { TablePrimaryButton } from "entities/ExcelTable/components";
import ArchiveContent from "entities/ExcelTable/components/Archive/ArchiveContent";
import { HurricaneSpinner, Modal } from "shared/components";
import { FileText } from "lucide-react";
import { TableTabItem, TabsParams } from "entities/ExcelTable/types";
import { availableYears } from "shared/constants/common";
import { useAppSelector } from "app/hooks";
import { useFirstLoading } from "hooks/useFirstLoading";
import { radioMonthDictionary } from "entities/ExcelTable/helpers/archive.constants";

type Props = {
    type: TabsParams;
    createTab: (item: TableTabItem) => void;
    tabs: TableTabItem[];
};

const ReportArchive: FC<Props> = memo((props) => {
    const { type, createTab, tabs } = props;
    const isReport = type === "balance";

    const [open, setOpen] = useState<boolean>(false);
    const { isLoadingState } = useAppSelector((state) => state.Excel);
    const isLoading = useFirstLoading(isLoadingState);

    const onCloseModal = () => {
        setOpen(false);
    };

    const textConfig = useMemo(() => {
        return {
            title: `Архив ${isReport ? "отчетов" : "нормативов"}`,
            description: `Тут находятся все ${
                isReport ? "отчеты" : "нормативы"
            }, которые были закрыты во время работы`,
        };
    }, [isReport]);

    return (
        <>
            <TablePrimaryButton
                onClick={() => setOpen(true)}
                disabled={isLoadingState}
                icon={<FileText fontSize={18} color="#191818" />}
            >
                {isLoading ? <HurricaneSpinner /> : textConfig.title}
            </TablePrimaryButton>
            <Modal
                closedOnAway={false}
                showed={open}
                closeShowed={onCloseModal}
                title={textConfig.title}
                description={textConfig.description}
            >
                {!isLoadingState && (
                    <ArchiveContent
                        type={type}
                        tabs={tabs}
                        isShowed={open}
                        closeShowed={onCloseModal}
                        createTab={createTab}
                        staticItems={
                            isReport
                                ? [
                                      {
                                          name: "settings",
                                          items: [
                                              {
                                                  items: [
                                                      {
                                                          key: "Месяц",
                                                          value: "Month",
                                                      },
                                                      {
                                                          key: "Год",
                                                          value: "Year",
                                                      },
                                                  ],
                                              },
                                          ],
                                      },
                                      {
                                          name: "Period",
                                          dependsOnName: 'settings',
                                          itemsInLine: 4,
                                          items: [
                                              {
                                                  activeValue: "Month",
                                                  items: radioMonthDictionary,
                                              },
                                              {
                                                  activeValue: "Year",
                                                  items: [
                                                      {
                                                          key: "2020",
                                                          value: "2020",
                                                      },
                                                      {
                                                          key: "2021",
                                                          value: "2021",
                                                      },
                                                      {
                                                          key: "2022",
                                                          value: "2022",
                                                      },
                                                      {
                                                          key: "2023",
                                                          value: "2023",
                                                      },
                                                      {
                                                          key: "2024",
                                                          value: "2024",
                                                      },
                                                  ],
                                              },
                                          ],
                                      },
                                  ]
                                : [
                                      {
                                          name: "Mode",
                                          items: [
                                              {
                                                  items: [
                                                      {
                                                          key: "Постоянные",
                                                          value: "CONST",
                                                      },
                                                      {
                                                          key: "Переменная",
                                                          value: "VARIABLE",
                                                      },
                                                  ],
                                              },
                                          ],
                                      },
                                      {
                                          name: "Period",
                                          itemsInLine: 4,
                                          startIndex: 3,
                                          items: [{ items: availableYears }],
                                      },
                                  ]
                        }
                    />
                )}
            </Modal>
        </>
    );
});

export default ReportArchive;
