import React, { FC } from "react";
import {
    IEventPosition,
    IPeriodModifier,
} from "services/pointEvents/pointEventsSlice";
import { changePeriod, PeriodTransitionWrapper } from "../../PeriodWrapper";

import { Input } from "antd";
import { RedLine } from "shared/components";

type Props = {
    period: IPeriodModifier;
    index: number;
    setPeriods: React.Dispatch<React.SetStateAction<IPeriodModifier[]>>;
    beginDate: string;
};

const TransitionPeriods: FC<Props> = (props) => {
    const { period, index: i, beginDate, setPeriods,  } = props;

    return (
        <>
            <PeriodTransitionWrapper
                period={period}
                setPeriods={setPeriods}
                beginDate={beginDate}
                i={i}
            />
            <div className="pair-wrapper">
                <div className="pair">
                    -
                    <Input
                        value={Math.abs(period?.Value?.[0])}
                        placeholder="Модификатор"
                        onChange={(e) =>
                            changePeriod(
                                setPeriods,
                                beginDate,
                                e.target.value,
                                i,
                                "Value",
                                0
                            )
                        }
                    />
                </div>
                <RedLine />
                <div className="pair">
                    +
                    <Input
                        type="text"
                        value={period?.Value?.[1]}
                        placeholder="Модификатор"
                        onChange={(e) =>
                            changePeriod(
                                setPeriods,
                                beginDate,
                                e.target.value,
                                i,
                                "Value",
                                1
                            )
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default TransitionPeriods;
