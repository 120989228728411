import React, { FunctionComponent } from "react";
import { Navigate, useLocation } from "react-router-dom";
import BalancePage from "../BalancePage";
import GuidePage from "../GuidePage";
import RenovationPage from "../RenovationPage";
import ISMainPage from "../ISMainPage";
import TemplateGuidePage from "../TemplateGuidePage";
import LoadFilePage from "../LoadFilePage";

const pageDictionary: Record<string, FunctionComponent> = {
    balance: ISMainPage,
    guides: ISMainPage,
    renovation: RenovationPage,
    view: GuidePage,
    heat: BalancePage,
    electric: BalancePage,
    compressed: BalancePage,
    fuel: BalancePage,
    templates: TemplateGuidePage,
    files: LoadFilePage
};

const ISContentPage = () => {
    const location = useLocation();

    const name = location.pathname.split("/").at(-1);
    const currentPage = pageDictionary[name ?? ""];
    if (!currentPage) return <Navigate to="/page-404" />;

    return (
        <>
            {React.createElement(
                pageDictionary[name as keyof typeof pageDictionary] ??
                    BalancePage
            )}
        </>
    );
};

export default ISContentPage;
