import { availableKeys } from "entities/ExcelTable/helpers/constants";
import { RefObject, useEffect, useRef, useState } from "react";
import { CellDirection } from "shared/types";

export interface IPickedConfig {
    pickedCells: { id: string; parentRow: number }[];
    operation: string;
}

const regExp = /^\([А-яA-z1234567890]{1}\) | \(Backspace\)$/;

export const usePickedCells = (
    table: RefObject<HTMLTableElement>,
    callback?: () => void,
    dependsOn?: string | null
) => {
    const [data, setData] = useState<IPickedConfig>(() => {
        return { pickedCells: [], operation: "" };
    });
    // замыкаине не работает в слушателе событий с обычным useState, но прекрасно работает с useRef
    const pickedCellsRef = useRef<{ id: string; parentRow: number }[]>([]);

    const setPickedCell = (ids: { id: string; parentRow: number }[]) => {
        pickedCellsRef.current = ids;
        setData((prev) => ({ ...prev, pickedCells: ids }));
    };

    useEffect(() => {
        callback?.();
        if (data.operation.length > 0) {
            setData((prev) => ({ ...prev, operation: "" }));
        }
    }, [data.pickedCells]);

    function horizontalIncreaseCell(
        direction: "left" | "right",
        element: Element,
        row: HTMLTableRowElement
    ) {
        const closestTd = element.closest("td");
        if (!closestTd) {
            setPickedCell([]);
            return;
        }
        const array = Array.from(row.children);
        if (direction === "left") {
            array.reverse();
        }
        const index = array.indexOf(closestTd);
        if (index < array.length) {
            const next = array[index + 1] as HTMLTableCellElement;
            setPickedCell([
                {
                    id: next.dataset.id ?? "",
                    parentRow: +(row.dataset.id ?? -1),
                },
            ]);
            // updateEditable(next.dataset.id);
        }
    }

    function increasePickedCell(e: KeyboardEvent) {
        const direction: CellDirection | undefined = availableKeys[e.key];

        if (!pickedCellsRef.current.length || !direction) return;

        const active = pickedCellsRef.current[0];

        const element = document.querySelector(`[data-id="${active.id}"]`);
        if (!element) return;

        const [rowInfo, columnInfo] = active.id.split(":");
        const row = element.closest("tr") as HTMLTableRowElement;

        if (["left", "right"].includes(direction)) {
            horizontalIncreaseCell(direction as "left" | "right", element, row);
            return;
        }

        let array = Array.from(table.current?.children || []);
        if (!array.length) return;
        array = Array.from(array.at(-1)?.children || []).filter(
            (el) => (el as HTMLTableRowElement).dataset.status !== "none"
        );
        if (direction === "top") {
            array.reverse();
        }
        const index = array.indexOf(row) + 1;
        if (index <= array.length - 1) {
            const elem = array[index] as HTMLTableRowElement;
            const dataIndex = [
                (array[index] as HTMLTableRowElement).dataset.row || "",
                columnInfo,
            ].join(":");
            setPickedCell([
                { id: dataIndex, parentRow: +(elem.dataset.id ?? -1) },
            ]);
        } else {
            // updateEditable(undefined);
        }
    }

    const setOperation = (value: string) => {
        if (!data.operation) {
            setData((prev) => ({ ...prev, operation: value }));
        }
    };

    useEffect(() => {
        if (!dependsOn) return;
        if (data.pickedCells?.[0].id !== dependsOn) {
            const element = document.querySelector(`[data-id="${dependsOn}"]`);
            if (!element) return;
            const row = (element as HTMLTableCellElement).closest(
                "tr"
            ) as HTMLTableRowElement;
            setData((prev) => ({
                ...prev,
                pickedCells: [
                    { id: dependsOn, parentRow: +(row.dataset.id ?? -1) },
                ],
            }));
        }
    }, [dependsOn]);

    return {
        pickedCells: data,
        setOperation,
        setData,
        setPickedCell,
        pickedCellsRef,
        increasePickedCell,
    };
};
