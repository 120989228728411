import { Flex, Modal } from "antd";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { TabsItem, TabsParams } from "entities/ExcelTable/types";
import Tab from "./Tab";
import { useActions, useAppSelector } from "app/hooks";
import {
    DefaultModalFooter,
    HurricaneSpinner,
    Slider,
} from "shared/components";
import { removeTab } from "entities/ExcelTable/api";

type Props = {
    items: TabsItem[];
    deleteItem: (id: number) => void;
    onCreateItem: () => void;
    createTabTitle: string;
    isLoadingTabs: boolean;
    type: TabsParams;
};

const TabsList: FC<Props> = (props) => {
    const {
        items,
        deleteItem,
        onCreateItem,
        createTabTitle,
        isLoadingTabs,
        type,
    } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { clearEditings } = useActions();
    const { activeIndex, promises, isEditingModalStatus } = useAppSelector(
        (state) => state.Excel
    );

    const onCloseOpen = () => {
        setCurId(null);
        setIsOpen(false);
    };
    const cleanCurrentindex = () => {
        if (activeIndex?.rate === curId) {
            const current =
                items.find((el) => el.Id !== curId) ?? items[0] ?? undefined;
        }
        onCloseOpen();
    };

    useEffect(() => {
        if (!items.find((el) => el.Id === curId)) {
            setCurId(null);
        }
    }, [items]);

    const [curId, setCurId] = useState<number | null>(null);

    const currentItem = useMemo<TabsItem | null>(() => {
        const item = items.find((el) => el.Id === curId);
        if (!item) return items?.[0] ?? null;
        return item;
    }, [curId, items]);

    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
            // @ts-ignore
            ref.current.calculateWidth();
        }
    }, [items]);

    const isInPromises = useMemo<boolean>(
        () => Object.keys(promises).includes(String(curId)),
        [promises, curId]
    );

    return (
        <>
            <Modal
                open={isOpen}
                title="Перенести в архив"
                centered
                width={900}
                onCancel={onCloseOpen}
                footer={() => (
                    <DefaultModalFooter
                        onAccept={() => {
                            deleteItem(curId ?? 0);
                            cleanCurrentindex();
                            clearEditings(curId ?? undefined);
                            removeTab({ type, tabId: curId ?? 0 });
                        }}
                        onReject={onCloseOpen}
                    />
                )}
            >
                {isInPromises
                    ? `Отчет "${currentItem?.ShortName}" имеет измененные ячейки. Примите изменения или отмените их перед удалением`
                    : `Вы действительно хотите перенести в архив отчет "${currentItem?.ShortName}"?
                 Перед архивированием рекомендуем сохранить все изменения`}
            </Modal>
            <Flex gap={8} align="center">
                {isLoadingTabs ? (
                    <HurricaneSpinner />
                ) : (
                    <Slider ref={ref} step={260} marginRight={500}>
                        <Flex gap={8} align="center" className="tab-list">
                            {items.map((item) => (
                                <Tab
                                    item={item}
                                    isEditingMode={
                                        isEditingModalStatus === "edit"
                                    }
                                    key={item.Id}
                                    openModal={(val: number) => {
                                        setCurId(val);
                                        setIsOpen(true);
                                    }}
                                />
                            ))}
                        </Flex>
                    </Slider>
                )}

                <Tab
                    item={{
                        ShortName: createTabTitle,
                        Name: "",
                        Id: Infinity,
                        GeneralReportTableId: Infinity,
                    }}
                    openModal={() => {}}
                    onCreateItem={onCreateItem}
                />
            </Flex>
        </>
    );
};

export default TabsList;
