import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { FC } from "react";

type Props = {
    color?: string;
    fontSize?: number;
};
const HurricaneSpinner: FC<Props> = (props) => {
    const { color, fontSize } = props;

    return (
        <Spin
            indicator={
                <LoadingOutlined
                    spin
                    style={{
                        fontSize: fontSize ?? 18,
                        color: color ?? "#191818",
                    }}
                />
            }
        />
    );
};

export default HurricaneSpinner;
