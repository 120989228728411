import { FC, useEffect, useMemo } from "react";
import {
    IEventPosition,
    IPeriodModifier,
    IPointEvent,
} from "services/pointEvents/pointEventsSlice";
import styles from "./styles.module.css";
import PeriodSelect from "./PeriodSelect";
import { Button, Tooltip } from "antd";
import PeriodPositionSelect from "../PeriodPositionSelect";
import { usePointEventsContext } from "entities/PointEvents/context/PointEventsContext";
import { EventPeriodType } from "entities/Types/types";

type Props = {
    period: IPeriodModifier;
    id: number;
    // updatePeriod: (period: IPeriodModifier) => void;
    availablePositions: IEventPosition[];
    periodsCount: number;
    removePeriod: (id: number) => void;
    updatePeriod: (value: Partial<IPeriodModifier>) => void;
    mainEventType: EventPeriodType;
};

const mappingSelectCountArray = [...Array(2).keys()];

const PeriodHeader: FC<Props> = (props) => {
    const {
        period,
        id,
        updatePeriod,
        availablePositions,
        periodsCount,
        mainEventType,
        removePeriod,
    } = props;

    const actualPeriods = useMemo(() => {
        const currentPosition = period.Position.map((el) => {
            const res = availablePositions.find((el2) => el2.Position === el);
            return res;
        });

        const ids = currentPosition.map((el) => el?.SupplyPointMappingId ?? -1);

        return {
            currentPosition,
            ids,
        };
    }, [period.Position, availablePositions]);

    const isTransition = mainEventType === "transition";

    const { currentObject } = usePointEventsContext();

    return (
        <>
            <div className={styles.period}>
                <span>Период {id + 1}</span>{" "}
                <Tooltip
                    title={
                        periodsCount > 1
                            ? ""
                            : "Вы не можете удалить последний период"
                    }
                >
                    <Button
                        onClick={() => removePeriod(id)}
                        disabled={periodsCount <= 1}
                    >
                        Удалить
                    </Button>
                </Tooltip>
            </div>
            <PeriodPositionSelect
                currentObject={currentObject ?? null}
                availablePositions={availablePositions}
                isTransition={isTransition}
                setCurrentPosition={(data: IEventPosition[]) =>
                    updatePeriod({
                        Position: data.map((el) => el.Position),
                    })
                }
                currentPosition={actualPeriods.currentPosition}
            />
            <div className={styles["period-mappings"]}>
                {(isTransition ? mappingSelectCountArray : [0]).map((item) => (
                    <PeriodSelect
                        key={item}
                        isTransition={isTransition}
                        positionId={actualPeriods.ids[item] ?? -1}
                        setPeriod={(val, id) => {
                            const current = structuredClone(period);
                            const actualElement = val;
                            if (!actualElement) {
                                current.Value[id] = 0;
                                current.CellMappingId[id] = 0;
                            } else {
                                current.Value[id] = actualElement.InstCapacity;
                                current.CellMappingId[id] = actualElement.Id;
                            }
                            const a = {
                                CellMappingId: current.CellMappingId,
                                Value: current.Value,
                            };
                            const b = {
                                CellMappingId: period.CellMappingId,
                                Value: period.Value,
                            };
                            if (JSON.stringify(a) !== JSON.stringify(b)) {
                                updatePeriod(a);
                            }
                        }}
                        period={period}
                        index={item}
                    />
                ))}
            </div>
        </>
    );
};

export default PeriodHeader;
