import {
    ICellRequest,
    ResponseTable,
    TableTabItem,
    TabsParams,
} from "../types";

import { ApiModule } from "shared/ApiModule";

export interface ExcelParams {
    TypeId: number | null;
    variant: TabsParams;
}

type TabParamName = 'reportTemplateId'

const tabsEndpoints: Record<TabsParams, {url: string; paramName: TabParamName} | null> = {
    balance: {url: "ReportTemplate/active", paramName: 'reportTemplateId'},
    guide: null,
};

export const getCurrentTabs = async (params: {
    type: TabsParams;
}): Promise<TableTabItem[]> => {
    const actualEndpoint = tabsEndpoints[params.type];
    if (!actualEndpoint) return [];
    const response = await ApiModule.get<TableTabItem[]>(
        actualEndpoint.url
    );
    return response || [];
};

export const removeTab = async (params: {
    type: TabsParams;
    tabId: number;
}): Promise<void> => {
    const actualEndpoint = tabsEndpoints[params.type];
    if (!actualEndpoint) return;
    await ApiModule.delete<{TabParamName: number}>(
        actualEndpoint.url, {[actualEndpoint.paramName]: params.tabId}
    );
}

export const makeTabActive = async (params: {
    type: TabsParams;
    tabId: number;
}): Promise<void> => {
    const actualEndpoint = tabsEndpoints[params.type];
    if (!actualEndpoint) return;
    await ApiModule.put<void, null>(
        actualEndpoint.url, null, {[actualEndpoint.paramName]: params.tabId}
    );
}

export const getRealTable = async (
    params?: ExcelParams
): Promise<ResponseTable | null> => {
    if (params?.TypeId === null) return null;
    const guides = await getGuideTables(params?.TypeId ?? -1);
    return guides || null;
};

const getGuideTables = async (
    reportTableId: number
): Promise<ResponseTable | null> => {
    const data = await ApiModule.get<ResponseTable>("/ReportTable", {
        reportTableId,
    });
    return data || null;
};

export const updateEditableCells = async (
    promises: Record<string, Record<string, string>>,
    url: string,
    templateName: string
): Promise<boolean | number> => {
    const data: ICellRequest[] = [];
    Object.entries(promises).forEach((pr) => {
        Object.entries(pr[1]).forEach((cell) => {
            data.push({
                [templateName]: parseInt(pr[0]),
                CellId: parseInt(cell[0]),
                Value: cell[1],
            });
        });
    });
    try {
        const result = await ApiModule.put<
            number | undefined | boolean,
            ICellRequest[]
        >(url, data);
        return result ?? false;
    } catch (e) {
        return false;
    }
};

export const deleteRate = (id: number) => {
    ApiModule.delete(`/RateTemplate?rateTemplateId=${id}`);
};
