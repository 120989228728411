import { ApiModule } from "shared/ApiModule";
import { IBalanceResponse, BalanceReport } from "../types";

export const getReportsArchive = async (): Promise<BalanceReport[]> => {
    const data = await ApiModule.get<IBalanceResponse[]>(`/ReportTemplate`);
    return (data || []).reverse().map((el) => ({
        ...el,
        ReportTableId: el.GeneralReportTableId,
        key: el.Id
    }));
};
