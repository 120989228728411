import { AddItemModal, EditableTable } from "../../components/Widgets";
import {
    updateCell,
    createCell,
    getCells,
    deleteCell,
} from "entities/MappingPositionsCell/api";
import { useFetch, useFilters } from "hooks";
import { columns } from "entities/EditableTable/Constants";
import { useEffect, useMemo, useState } from "react";
import { AddButton, BigLoader } from "../../components/UI";
import { Flex } from "antd";
import { SearchInput } from "shared/components";
import { useDebounce } from "app/hooks";
import { ISupplyPointCell } from "services/tableMappings/cellMappingSlice";

const fieldsForCreate = columns.slice(0, -1);

export const PointcellMappingPage = () => {
    const { data, trigger } = useFetch(getCells, null);

    const [showed, setShowed] = useState(false);
    const { filter, updateFilters } = useFilters([
        { name: "search", type: "Input" },
    ]);
    const [filterValue, setFilterValue] = useState<string>(
        typeof filter.search === "string" ? filter.search : ""
    );
    const debouncedSearchPattern = useDebounce(filterValue);

    useEffect(() => {
        updateFilters("search", debouncedSearchPattern);
    }, [debouncedSearchPattern]);

    const filteredData = useMemo<ISupplyPointCell[] | undefined>(() => {
        if (!data) return undefined;
        if (!filter.search) return data;
        const str = String(filter.search).toLowerCase();
        return data.filter((cell) =>
            Object.values(cell).some(
                (value) =>
                    typeof value === "string" &&
                    value.toLowerCase().includes(str)
            )
        );
    }, [data, filter.search]);

    if (!filteredData) return <BigLoader />;
    return (
        <>
            <h1>Ячейки по ОЛС</h1>
            <Flex style={{ marginBlock: 15 }}>
                <SearchInput
                    className="search"
                    value={filterValue}
                    placeholder="Умный поиск"
                    onChange={(e) => setFilterValue(e.target.value)}
                />
                <AddButton
                    onClick={() => {
                        setShowed((prev) => !prev);
                    }}
                >
                    Создать ячейку
                </AddButton>
            </Flex>

            <EditableTable
                data={filteredData}
                refetch={trigger}
                columns={columns}
                actions={[
                    {
                        type: "edit",
                        action: updateCell,
                    },
                    {
                        type: "delete",
                        action: deleteCell,
                    },
                ]}
            />
            <AddItemModal
                title="Создание ячейки"
                showed={showed}
                fields={fieldsForCreate}
                closeShowed={() => setShowed(false)}
                onAccept={async (data: any) => {
                    await createCell(data).then(trigger);
                }}
                description="Введите данные для создания ячейки и сохраните изменения"
            />
        </>
    );
};
