import { Modal, Radio } from "antd";
import { createElement, FC, useMemo } from "react";
import { getRealTable } from "entities/ExcelTable/api";
import { periodConfiguration } from "entities/ExcelTable/helpers/table.constants";
import { PeriodConfiguration, TablePageProps } from "entities/ExcelTable/types";
import { usePeriodFilter } from "ExcelTable/components/ExcelHeaderPanel/ExcelHeaderTitle/hooks/usePeriodFIlter";
import { HiddenContainer } from "ExcelTable/shared/components";
import { useEffectSkipFirstRender, useFetch } from "hooks";
import { getReportName2 } from "ExcelTable/helpers/methods";
import { withExcelPage } from "entities/ExcelTable/hocs/withExcelPage";
import { DefaultModalFooter } from "shared/components";
import { useActions, useAppSelector } from "app/hooks";
import { TableBlock } from "widgets/ExcelTable";
import { createReport } from "entities/ISGuide/api";
import { useArchiveToReport } from "entities/ExcelTable/hooks/useArchiveToReport";

const BalancePage: FC<TablePageProps> = (props) => {
    const { isEditingModalStatus, resources, activeIndex } =
        useAppSelector((state) => state.Excel);
    const { changeEditingModalOpeningStatus } = useActions();

    const {
        currentTableItem,
        updateNewItem,
        cancelModal,
        trigger,
        isBottomTableShowed,
    } = props;

    const { filter, changeFilter, cleanFilters, settings, updateSettings } =
        usePeriodFilter(isEditingModalStatus !== false);

    const {
        data,
        trigger: refetch,
        isLoading,
    } = useFetch(getRealTable, {
        TypeId: activeIndex?.table ?? null,
        variant: "balance",
    });

    useEffectSkipFirstRender(() => {
        refetch();
    }, trigger);

    const onCancelModal = () => {
        cancelModal();
        cleanFilters();
    };

    const resourceId = useMemo(
        () =>
            resources.find((el) => el.EnergySourceName === "Электроэнергия")
                ?.EnergySourceId ?? 0,
        [resources]
    );

    const generateReportName = useArchiveToReport();

    return (
        <>
            <Modal
                width={1100}
                open={isEditingModalStatus !== false}
                title={`${
                    isEditingModalStatus === "edit"
                        ? "Редактировать"
                        : "Сформировать"
                } отчет по периоду`}
                onCancel={onCancelModal}
                onClose={onCancelModal}
                footer={() => (
                    <DefaultModalFooter
                        onAccept={() => {
                            const res = generateReportName({
                                ...filter,
                                mode: settings,
                            });
                            updateNewItem({
                                mode: settings,
                                filter,
                                resourceId,
                                shortName: res.ShortName,
                                name: res.Name,
                            });
                            changeEditingModalOpeningStatus(false);
                        }}
                        onReject={onCancelModal}
                    />
                )}
            >
                <Radio.Group
                    onChange={(e) => updateSettings(e.target.value)}
                    value={settings}
                    style={{
                        marginBottom: 8,
                    }}
                >
                    {periodConfiguration.map(({ name, code }) => (
                        <Radio.Button key={code} value={code}>
                            {name}
                        </Radio.Button>
                    ))}
                </Radio.Group>
                {periodConfiguration.map(({ code, Value }) => (
                    <HiddenContainer isHidden={settings !== code} key={code}>
                        {createElement(Value, {
                            filters: filter,
                            updateFilter: changeFilter,
                            isActive: settings === code,
                        })}
                    </HiddenContainer>
                ))}
            </Modal>
            <TableBlock
                currentTable={data}
                title={currentTableItem?.Name ?? ""}
                isLoading={isLoading}
                isBottomTableShowed={isBottomTableShowed}
            />
        </>
    );
};

export default withExcelPage<
    PeriodConfiguration & {
        resourceId: number;
        name: string;
        shortName: string;
    }
>(
    BalancePage,
    "balance",
    getReportName2,
    (data: { [key: string]: string | number | null }) =>
        getReportName2(data, true),
    "Новый отчет",
    createReport
);
