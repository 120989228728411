import { ApiModule } from "shared/ApiModule";
import {
    ICreatingFormResponse,
    IRatesParams,
    IReportCreateResponse,
    IReportParams,
    IResource,
    ITemplate,
    ITemplatePosition,
    ResourceMode,
    TemplateMap,
    TemplateResponse,
} from "../types";
import { IGuideRate, PeriodConfiguration } from "entities/ExcelTable/types";
import { getLastMonthDay, parseDateFromFrontToBack } from "shared/helpers";

const quartalDictionary: Record<
    string,
    { value: number; start: string; end: string }
> = {
    first: { value: 1, start: "01.01", end: "31.03" },
    second: { value: 2, start: "01.04", end: "30.06" },
    third: { value: 3, start: "01.07", end: "30.09" },
    fourth: { value: 4, start: "01.10", end: "31.12" },
};

export const getTemplates = async (): Promise<ITemplate[]> => {
    const response = await ApiModule.get<ITemplate[]>(
        "/SupplyPointPositionEnergySource"
    );

    return response ?? [];
};

export const getTemplatePositions = async (): Promise<ITemplatePosition[]> => {
    const response = await ApiModule.get<ITemplatePosition[]>(
        "/user-supply-point-position-mapping/positions "
    );

    return response ?? [];
};

type BodyType = {
    EffectiveDate: string;
    Mode: ResourceMode;
    PositionId: number;
    EnergySourceId: string;
};

export const setTemplatePosition = async (
    positions: number[],
    body: TemplateMap,
    year: string
) => {
    let result: BodyType[] = [];
    positions.forEach((pos) => {
        const parsed: BodyType[] = Object.values(body).reduce<any[]>(
            (acc: any[], cur: Record<string, IResource["Mode"]>) => {
                const parsed = Object.entries(cur).map(([key, value]) => {
                    const obj = {
                        EffectiveDate: year,
                        Mode: value,
                        PositionId: pos,
                        EnergySourceId: key,
                    };
                    return obj;
                });
                return acc.concat(parsed);
            },
            []
        );
        result = result.concat(parsed);
    });
    await ApiModule.post("/SupplyPointPositionEnergySource", result);
    const response = positions.reduce<TemplateResponse>((acc, cur: number) => {
        return { ...acc, [cur]: { body, EffectiveDate: year } };
    }, {});
    return response;
};

export const getCreatingFormConfig = async (): Promise<
    ICreatingFormResponse[]
> => {
    const response = await ApiModule.get<ICreatingFormResponse[]>(
        "/EnergySource"
    );
    return response ?? [];
};

export const getRates = async (params?: {
    [key: string]: string;
}): Promise<IGuideRate[]> => {
    const data = await ApiModule.post<
        Omit<IGuideRate, "key">[],
        Partial<IRatesParams>
    >("/RateTemplate/list", params);
    return (data || []).map((rate) => ({ ...rate, key: rate.Id }));
};

type GoodRate = {
    NewReportTableId: number;
    NewRateTemplateId: number;
};

type BadRate = {
    ExistRateTemplateId: number;
    ExistReportTableId: number;
};

const isGoodRate = (rate: GoodRate | BadRate | undefined): rate is GoodRate => {
    return !!rate && "NewReportTableId" in rate;
};

export const createRate = async (data: IRatesParams) => {
    const response = await ApiModule.post<GoodRate | BadRate, IRatesParams>(
        "/RateTemplate/generate",
        data
    );
    if (isGoodRate(response))
        return {
            id: response.NewReportTableId,
            tableId: response.NewReportTableId,
            success: true,
        };
    return {
        id: response?.ExistRateTemplateId ?? 0,
        tableId: response?.ExistReportTableId ?? 0,
        success: false,
    };
};

export const createReport = async (
    data: PeriodConfiguration & { resourceId: number; name: string; shortName: string; }
) => {
    let parsed: IReportParams | undefined;
    if (data.mode === "Month") {
        const dateslav = getLastMonthDay(data.filter.year, data.filter.month);

        const a = data.filter.date
            ? data.filter.date.split(".").reverse().join("-") + "T00:00:00"
            : "";

        parsed = {
            EnergySourceId: data.resourceId,
            TemplateName: data.name,
            TemplateShortName: data.shortName,
            StartDate: parseDateFromFrontToBack(
                new Date(data.filter.year, data.filter.month - 1, 1)
            ),
            EndDate:
                data.filter.period === "untilToday"
                    ? parseDateFromFrontToBack(new Date())
                    : data.filter.period === "untilDate"
                    ? a
                    : parseDateFromFrontToBack(
                          new Date(
                              data.filter.year,
                              data.filter.month - 1,
                              dateslav
                          )
                      ),
        };
    }
    if (data.mode === "Quartal") {
        const range = quartalDictionary[data.filter.period].value ?? 1;
        parsed = {
            EnergySourceId: data.resourceId,
            TemplateName: data.name,
            TemplateShortName: data.shortName,
            StartDate: parseDateFromFrontToBack(
                new Date(data.filter.year, 3 * (range - 1), 1)
            ),
            EndDate: parseDateFromFrontToBack(
                new Date(
                    data.filter.year,
                    3 * (range - 1) + 2,
                    getLastMonthDay(data.filter.year, 3 * range)
                )
            ),
        };
    }
    if (data.mode === "Year") {
        parsed = {
            EnergySourceId: data.resourceId,
            TemplateName: data.name,
            TemplateShortName: data.shortName,
            StartDate: parseDateFromFrontToBack(
                new Date(data.filter.year, 0, 1)
            ),
            EndDate: parseDateFromFrontToBack(
                new Date(data.filter.year, 11, 31)
            ),
        };
    }

    const response = await ApiModule.post<IReportCreateResponse, IReportParams>(
        "/ReportTemplate/simpleReport",
        parsed
    );
    if (!response) {
        return {
            id: 0,
            success: false,
            name: "",
            tableId: 0,
        };
    }
    return {
        id: response.Id,
        success: true,
        name: response.Name,
        tableId: response.GeneralReportTableId,
    };
};
