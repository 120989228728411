import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    ColorPallete,
    ITableCell,
    PeriodConfiguration,
    TableVariants,
} from "../types";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ICreatingFormResponse } from "entities/ISGuide/types";
import { getCreatingFormConfig } from "entities/ISGuide/api";
import { FilterValues, IRadioFilterItem } from "shared/types/is-energy";
import { setDeep } from "shared/helpers";
import { reportSubFilters } from "../helpers/archive.constants";

const base: GuideCreatingForm = {
    form: [
        { name: "energy", items: [] },
        { name: "EnergySourceId", dependsOnName: "energy", items: [] },
        // {
        //     name: "settings",
        //     items: [
        //         {
        //             items: [
        //                 {
        //                     key: "Месяц",
        //                     value: "Month",
        //                 },
        //                 {
        //                     key: "Год",
        //                     value: "Year",
        //                 },
        //             ],
        //         },
        //     ],
        // },
    ],
    // subFilters: [
    //     // { name: "EnergySourceId", dependsOn: "energy", values: [] },
    //     // { key: "settings", values: reportSubFilters },
    // ],
    // getActualForm(filter?: { [key: string]: string }, slicer?: number) {
    //     if (this.form.length !== this.subFilters.length) {
    //         return this.form;
    //     }
    //     const app = this.form
    //         .slice(0, slicer ?? this.form.length + 1)
    //         .reduce(
    //             (
    //                 acc: IRadioFilterItem[],
    //                 cur: IRadioFilterItem,
    //                 id: number
    //             ) => {
    //                 const subFilter = this.subFilters[id];
    //                 if (!subFilter.values.length) return [...acc, cur];

    //                 const active =
    //                     subFilter.values.find(
    //                         (el) => el.name === filter?.[this.form[id].name]
    //                     ) ?? subFilter.values[0];
    //                 return [...acc, cur, { ...active, name: subFilter.key }];
    //             },
    //             []
    //         );

    //     return app;
    // },
};

type GuideCreatingForm = {
    form: IRadioFilterItem[];
    // subFilters: {
    //     name: string;
    //     dependsOn: string;
    //     values: IRadioFilterItem[];
    // }[];
    // getActualForm: (
    //     value?: { [key: string]: string },
    //     slicer?: number
    // ) => IRadioFilterItem[];
};

type ActiveIndex = {
    table: number | null;
    rate: number;
    name: string;
};

interface InitialState {
    promises: Record<string, Record<string, string>>;
    filteredString: string;
    colors: ColorPallete;
    currentVariant: TableVariants;
    filterPeriodCurrent: PeriodConfiguration | null;
    activeIndex: ActiveIndex | null;
    fontSize: string;
    pointedRows: number[];
    actionCell: ITableCell | null;
    titleGenerator: (filter: {
        [key: string]: string | number | null;
    }) => string;
    showHiddenValues: boolean;
    resources: ICreatingFormResponse[];
    guideCreatingForm: GuideCreatingForm;
    isLoadingState: boolean;
    // функционал для переноса заголовка отчета в хлебные крошки
    isEditingModalStatus: false | "creating" | "edit";
}

const fontSizeFromStorage: string | null = localStorage.getItem("fontSize");

const initialColors = localStorage.getItem("colors");

const initialState: InitialState = {
    promises: {},
    colors: initialColors
        ? JSON.parse(initialColors)
        : {
              header: "#fefe22",
              name: "#ccffcc",
              column: "#ccffcc",
              bottom: "#99ccff",
          },
    filteredString: "",
    currentVariant: "Electric",
    filterPeriodCurrent: null,
    activeIndex: null,
    fontSize: fontSizeFromStorage ?? "16",
    pointedRows: [],
    actionCell: null,
    titleGenerator: (item: { [key: string]: string | number | null }) => "",
    showHiddenValues: false,
    isLoadingState: true,
    resources: [],
    guideCreatingForm: {
        form: [],
        // subFilters: [],
        // getActualForm(filter?: { [key: string]: string }) {
        //     return [];
        // },
    },
    isEditingModalStatus: false,
};

export const fetchResources = createAsyncThunk(
    "asyncGetResources",
    async (_, thunkApi) => {
        const response = await getCreatingFormConfig();

        return response;
    }
);

const excelSlice = createSlice({
    name: "excelSlice",
    initialState,
    reducers: {
        addEditing: (state, action: PayloadAction<[string, string][]>) => {
            const [key, value] = action.payload;
            state.promises = setDeep(
                action.payload,
                String(state.activeIndex?.rate),
                state.promises
            );
        },
        // довольно не тривиальная логика, стоит расписать, может улучшить: проверяем на то, что отредактируемое значение вернулось в исходное состояние. Если это так, удаляем это поле из объекта (объекта текущей таблицы). Возможен случай, что и сам объект может стать пустым - в таком случае полностью удаляем его из promises
        removeFromEditing: (state, action: PayloadAction<string>) => {
            const rate = state.activeIndex?.rate;
            if (rate) {
                const current = { ...state.promises[String(rate)] };
                delete current[action.payload];
                if (Object.values(current).length === 0) {
                    const promisesClone = { ...state.promises };
                    delete promisesClone[String(rate)];
                    state.promises = promisesClone;
                } else {
                    state.promises = {
                        ...state.promises,
                        [String(rate)]: current,
                    };
                }
            }
        },
        clearEditings: (state, action: PayloadAction<number | undefined>) => {
            if (!action.payload) {
                state.promises = {};
            } else {
                const clone = { ...state.promises };
                delete clone[String(action.payload)];
                state.promises = clone;
            }
            return state;
        },
        setFilterValue: (state, action: PayloadAction<string>) => {
            state.filteredString = action.payload;
        },
        changeColor: (
            state,
            action: PayloadAction<{ key: keyof ColorPallete; color: string }>
        ) => {
            state.colors = {
                ...state.colors,
                [action.payload.key]: action.payload.color,
            };
            localStorage.setItem("colors", JSON.stringify(state.colors));
        },
        rewriteColors: (state, action: PayloadAction<ColorPallete>) => {
            state.colors = action.payload;
            localStorage.setItem("colors", JSON.stringify(state.colors));
        },
        formPeriod: (
            state,
            action: PayloadAction<PeriodConfiguration | null>
        ) => {
            state.filterPeriodCurrent = action.payload;
        },
        changeActiveIndex: (
            state,
            action: PayloadAction<ActiveIndex | null>
        ) => {
            state.activeIndex = action.payload;
            state.pointedRows = [];
        },
        changeActiveIndexTemplateId: (state, action: PayloadAction<number>) => {
            if (state.activeIndex !== null) {
                state.activeIndex.table = action.payload;
            }
        },
        togglePointedRows: (state, action: PayloadAction<number>) => {
            state.pointedRows = state.pointedRows.includes(action.payload)
                ? state.pointedRows.filter((row) => row !== action.payload)
                : [...state.pointedRows, action.payload];
        },
        setFontSize: (state, action: PayloadAction<string>) => {
            state.fontSize = action.payload;
            localStorage.setItem("fontSize", state.fontSize);
        },
        changeShowHiddenValuesStatus: (
            state,
            action: PayloadAction<boolean | undefined>
        ) => {
            state.showHiddenValues = action.payload ?? !state.showHiddenValues;
        },
        changeEditingModalOpeningStatus: (
            state,
            action: PayloadAction<false | "creating" | "edit">
        ) => {
            state.isEditingModalStatus = action.payload;
        },
        triggerLoader: (state, action: PayloadAction<boolean>) => {
            if (state.isLoadingState !== action.payload) {
                state.isLoadingState = action.payload;
            }
        },
        regenerateTitle: (
            state,
            action: PayloadAction<
                (filter: { [key: string]: string | number | null }) => string
            >
        ) => {
            state.titleGenerator = action.payload;
            return state;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchResources.fulfilled, (state, action) => {
            if (
                JSON.stringify(state.resources) !==
                JSON.stringify(action.payload)
            ) {
                state.resources = action.payload;

                const energyItems: FilterValues = [{ items: [] }];
                const energySourceItems: FilterValues = [];

                action.payload.forEach((el) => {
                    if (
                        energyItems[0].items.every(
                            (item) => item.value !== el.AxisCode
                        )
                    ) {
                        energyItems[0].items.push({
                            key: el.AxisName,
                            value: el.AxisCode,
                        });
                    }
                    const idx = energySourceItems.findIndex(
                        (itm) => itm.activeValue === el.AxisCode
                    );
                    if (idx !== -1) {
                        energySourceItems[idx].items.push({
                            key: el.EnergySourceName,
                            value: String(el.EnergySourceId),
                        });
                    } else {
                        energySourceItems.push({
                            items: [
                                {
                                    key: el.EnergySourceName,
                                    value: String(el.EnergySourceId),
                                },
                            ],
                            activeValue: el.AxisCode,
                        });
                    }
                });
                state.guideCreatingForm = {
                    form: [
                        { name: "energy", items: energyItems },
                        {
                            name: "EnergySourceId",
                            dependsOnName: "energy",
                            items: energySourceItems,
                        },
                    ],
                };

                // let chida = action.payload.reduce<GuideCreatingForm>(
                //     (acc: GuideCreatingForm, cur: ICreatingFormResponse) => {
                //         const energyItem = acc.form.find(
                //             (el) => el.name === "energy"
                //         )!;
                //         const hasElem = energyItem.items.find(
                //             (el) => el.value === cur.AxisCode
                //         );

                //         if (hasElem) {
                //             acc = {
                //                 ...acc,
                //                 subFilters: acc.subFilters.map((fil, id) =>
                //                     id === 0
                //                         ? {
                //                               ...fil,
                //                               values: fil.values.map((el) =>
                //                                   el.name === cur.AxisCode
                //                                       ? {
                //                                             ...el,
                //                                             items: [
                //                                                 ...el.items,
                //                                                 {
                //                                                     key: cur.EnergySourceName,
                //                                                     value: String(
                //                                                         cur.EnergySourceId
                //                                                     ),
                //                                                 },
                //                                             ],
                //                                         }
                //                                       : el
                //                               ),
                //                           }
                //                         : fil
                //                 ),
                //             };
                //         } else {
                //             acc = {
                //                 ...acc,
                //                 form: acc.form.map((el) =>
                //                     el.name === "energy"
                //                         ? {
                //                               ...el,
                //                               items: [
                //                                   ...el.items,
                //                                   {
                //                                       key: cur.AxisName,
                //                                       value: cur.AxisCode,
                //                                   },
                //                               ],
                //                           }
                //                         : el
                //                 ),
                //                 subFilters: acc.subFilters.map((fil, id) =>
                //                     id === 0
                //                         ? {
                //                               ...fil,
                //                               values: [
                //                                   ...fil.values,
                //                                   {
                //                                       name: cur.AxisCode,
                //                                       items: [
                //                                           {
                //                                               key: cur.EnergySourceName,
                //                                               value: String(
                //                                                   cur.EnergySourceId
                //                                               ),
                //                                           },
                //                                       ],
                //                                   },
                //                               ],
                //                           }
                //                         : fil
                //                 ),
                //             };
                //         }
                //         return acc;
                //     },
                //     base
                // );
                // chida = {
                //     ...chida,
                //     form: [...chida.form],
                // };
                // state.guideCreatingForm = chida;
            }
            state.isLoadingState = false;
        });
        builder.addCase(fetchResources.pending, (state) => {
            state.isLoadingState = true;
        });
        builder.addCase(fetchResources.rejected, (state) => {
            state.resources = [];
            state.isLoadingState = false;
        });
    },
});

export const { actions, reducer } = excelSlice;
