import { Select } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { ICellMapping } from "entities/MappingPositionsCell/Types/mapPositions";
import { IPeriodModifier } from "services/pointEvents/pointEventsSlice";
import { getCellMappings } from "entities/MappingPositionsCell/api/mapTable";

const { Option } = Select;

type Props = {
    setPeriod(val: ICellMapping | undefined, index: number): void;
    index: number;
    period: IPeriodModifier;
    positionId: number;
    isTransition: boolean;
};

const PeriodSelect: FC<Props> = (props) => {
    const { setPeriod, index, period, positionId, isTransition } = props;

    const [realOptions, setRealOptions] = useState<ICellMapping[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (positionId === -1) return;
        setIsLoading(true);
        getCellMappings({
            targetSupplyPointPositionId: positionId,
        })
            .then((data) => {setRealOptions(data); })
            .finally(() => {
                setIsLoading(false);
            });
    }, [positionId]);

    const filteredOptions = useMemo(() => {
        if (realOptions === undefined) return [];
        if (!isTransition) return realOptions;
        const choosedMaps = [...period.CellMappingId];
        choosedMaps.splice(index, 1);
        return realOptions.filter((el) => !choosedMaps.includes(el.Id));
    }, [realOptions, index, period.CellMappingId]);

    useEffect(() => {
        if (
            filteredOptions.some(
                (el) => el.Id === period.CellMappingId[index]
            ) ||
            isLoading
        )
            return;
        let defaultC = filteredOptions.find((el) => el.IsDefault);
        if (!defaultC && filteredOptions.length === 1) {
            defaultC = filteredOptions[0];
        }
        setPeriod(defaultC, index);
    }, [filteredOptions]);

    return (
        <Select
            notFoundContent="Не найдено"
            placeholder="Ячейки"
            className="editable-select"
            onChange={(val: number) =>
                setPeriod(
                    (realOptions ?? []).find((el) => el.Id === val),
                    index
                )
            }
            value={period.CellMappingId[index] || null}
        >
            {filteredOptions.map((el) => (
                <Option value={el.Id} key={el.Id}>
                    {el.Description ??
                        `От ${el.SourceSupplyPointName} до ${el.TargetSupplyPointName}`}
                </Option>
            ))}
        </Select>
    );
};

export default PeriodSelect;
