import "./styles/App.scss";
import "./styles/normalize.css";
import "./styles/fonts.css";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Header from "./components/Header/header";
import { useGetCurrentUser } from "./hooks/currentUserHook";
import { BigLoader } from "./components/UI";
import ProtectedRoute from "./shared/components/ProtectedRoute";
import { ConfigProvider } from "antd";

function App() {
    const { status, realRoutes } = useGetCurrentUser();

    const isLoading: boolean = status === "pending";

    const { actualRoutes, defaultPath } = realRoutes;
    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        primaryColor: "#e22739",
                        colorIconHover: "#e22739",
                        colorInfoTextHover: "#e22739",
                        defaultHoverColor: "#e22739",
                        defaultHoverBorderColor: "#e22739",
                        defaultActiveColor: "#e22739",
                        defaultActiveBorderColor: "#e22739",
                    },
                },
            }}
        >
            <div className="event-page-container">
                <Header realRoutes={realRoutes} />
                {isLoading ? (
                    <BigLoader />
                ) : (
                    <Routes>
                        {actualRoutes.map(
                            ({
                                Component,
                                children,
                                path,
                                name,
                                permission,
                                MotherComponent,
                            }) => (
                                <Route
                                    key={path}
                                    path={path}
                                    element={
                                        <ProtectedRoute
                                            name={name}
                                            permission={permission ?? null}
                                        >
                                            {children?.length ? (
                                                MotherComponent ? (
                                                    MotherComponent
                                                ) : (
                                                    <Outlet />
                                                )
                                            ) : (
                                                Component
                                            )}
                                        </ProtectedRoute>
                                    }
                                >
                                    {children && (
                                        <>
                                            <Route
                                                index
                                                element={
                                                    <ProtectedRoute
                                                        name={name}
                                                        permission={
                                                            permission ?? null
                                                        }
                                                    >
                                                        {Component}
                                                    </ProtectedRoute>
                                                }
                                            />
                                            {children.map((el) => (
                                                <Route
                                                    path={el.path}
                                                    key={el.path}
                                                    element={
                                                        <ProtectedRoute
                                                            name={el.name}
                                                            permission={
                                                                permission ??
                                                                null
                                                            }
                                                        >
                                                            {el.Component}
                                                        </ProtectedRoute>
                                                    }
                                                />
                                            ))}
                                        </>
                                    )}
                                </Route>
                            )
                        )}
                        {/* <Route path="/" element={<LoginPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                        path="/point-events-acceptor"
                        element={<PointEventsPage />}
                    />
                    <Route path="positions" element={<CellPage />} />
                    <Route
                        path="/point-events-lineman"
                        element={<PointEventsPage />}
                    />
                    <Route
                        path="/supply-point-cell-mapping"
                        element={<PointcellMappingPage />}
                    />
                    <Route
                        path="/mapping-cells"
                        element={<MappingCellPage />}
                    />
                    <Route
                        path="/mapping-object-users"
                        element={<MappingUserObjectPage />}
                    />
                    <Route path="/admin-side" element={<CmsPage />} />
                    <Route path="/types-creating" element={<TypesPage />} />
                    <Route
                        path="is-energy"
                        element={<TablePage tables={propsiki} />}
                    /> */}
                        <Route
                            path="*"
                            element={<Navigate to={defaultPath} />}
                        />
                    </Routes>
                )}
            </div>
        </ConfigProvider>
    );
}

export default App;
