import { FC } from "react";
import { Col, Flex, Row, Typography } from "antd";
import ExcelColorItem from "./ExcleColorItem";
import { ColorPallete } from "entities/ExcelTable/types";
import RedButton from "shared/components/RedButton";
import { useActions } from "app/hooks";

type ColorDictionary = Record<keyof ColorPallete, string>;
const dictionary: ColorDictionary = {
    header: "Строка заголовка",
    name: "Колонка с наименованием",
    column: "Колонка со значением",
    bottom: 'Сводная таблица'
};

type Props = {
    colors: ColorPallete;
    changeColor: (key: keyof ColorPallete, color: string) => void;
    onClose: () => void;
};

const ExcelColorsSettings: FC<Props> = ({ onClose, colors, changeColor }) => {
    const { rewriteColors: rewrite } = useActions();
    const keys = Object.keys(colors) as (keyof ColorPallete)[];

    function closeModal() {
        rewrite(colors);
        onClose();
    }

    return (
        <Flex vertical gap={10}>
            <Row gutter={12}>
                {keys.map((key) => (
                    <Col key={key} span={6}>
                        <Flex vertical key={key} gap={10}>
                            <Typography.Title
                                level={5}
                                style={{
                                    margin: 0,
                                }}
                            >
                                {dictionary[key]}
                            </Typography.Title>

                            <ExcelColorItem
                                color={colors[key]}
                                changeColor={(color: string) =>
                                    changeColor(key, color)
                                }
                            />
                        </Flex>
                    </Col>
                ))}
            </Row>

            <RedButton onClick={closeModal}>Сохранить</RedButton>
        </Flex>
    );
};

export default ExcelColorsSettings;
