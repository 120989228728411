import { useEffect, useMemo, useState } from "react";
import { getCurrentTabs } from "../api";
import { TablePageProps, TableTabItem, TabsParams } from "../types";
import { useActions, useAppSelector } from "app/hooks";
import { Flex, Modal } from "antd";
import { TableSearcher, TabsList } from "../components";
import { Archive } from "widgets/ExcelTable";
import { callSuccess } from "shared/helpers";

export const withExcelPage = <T extends object>(
    Component: React.ComponentType<TablePageProps>,
    type: TabsParams,
    renameFunction: (data: { [key: string]: any }) => string,
    setShortName: (data: { [key: string]: any }) => string,
    newTabText: string,
    creatableFunction: (
        data: T
    ) => Promise<
        | { id: number; success: boolean; tableId: number; name?: string }
        | undefined
    >
) => {
    return () => {
        const { titleGenerator, activeIndex, isEditingModalStatus, promises } =
            useAppSelector((state) => state.Excel);

        const isEditableMode = isEditingModalStatus === "edit";

        function updateActiveIndex(item?: TableTabItem) {
            if (item) {
                changeActiveIndex({
                    rate: item.Id,
                    table: item.GeneralReportTableId,
                    name: item.Name,
                });
            } else {
                changeActiveIndex(null);
            }
        }

        function removeTab(id: number) {
            const filtered = tabs.filter((item) => item.Id !== id);
            setTabs(filtered);
            if (id === activeIndex?.rate) {
                updateActiveIndex(filtered[0]);
            }
        }

        const updateNewItem = (val: { [key: string]: any }) => {
            if (isEditableMode) {
                modifiyTabs(val);
            } else {
                modifiyTabs(val);
            }
        };

        const createNewTab = (item: TableTabItem) => {
            if (tabs.some((tab) => tab.Id === item.Id)) {
                updateActiveIndex(item);
                return;
            }
            setTabs((prev) => [...prev, item]);
        };

        async function modifiyTabs(newItemModel?: {
            [key: string]: string | number | null;
        }) {
            const obj = newItem ?? newItemModel;
            if (!obj) return;
            const newItemId = await creatableFunction(obj as T);
            if (newItemId === undefined) return;
            // если сейчас есть newItem, значит, мы нажали на кнопку "Принять" при подтверждении. Этот процесс не обрабатываем. а просто заменяем табчик (написано на момент 05.12, бека для редактирования нет)
            if (!!newItem || isEditableMode) {
                setTabs((prev) =>
                    prev.map((el) =>
                        el.Id === activeIndex?.rate
                            ? { ...el, filtersState: obj }
                            : el
                    )
                );
            } else {
                // в противном случае (который говорит о том, что мы создаем элемент) смотрим на результат запроса. На момент 05.12 ошибка могла возникнуть при попытке создать отчет по нормам, который уже есть в архиве

                if (newItemId.success) {
                    createNewTab({
                        Id: newItemId.id,
                        GeneralReportTableId: newItemId.tableId,
                        Name: newItemId.name ?? titleGenerator(obj),
                        ShortName: newItemId.name
                            ? newItemId.name.split("-")[0].split("[")[0]
                            : setShortName(obj),
                        filtersState: obj,
                    });
                    changeActiveIndex({
                        rate: newItemId.id,
                        table: newItemId.tableId,
                        name: newItemId.name ?? `Новый отчет № ${newItemId.id}`,
                    });
                } else {
                    setErrorId(newItemId.id);
                }
            }
            if (newItemId.success || isEditableMode) {
                callSuccess(
                    `Отчет ${isEditableMode ? "изменен" : "сформирован"}`
                );
            }
            setNewItem(null);
        }

        const [tabs, setTabs] = useState<TableTabItem[]>([]);
        const [isLoadingTabs, setIsLoadingTabs] = useState<boolean>(true);

        const [trigger, setTrigger] = useState<boolean>(false);
        const [newItem, setNewItem] = useState<{
            [key: string]: string | number | null;
        } | null>(null);

        const [errorId, setErrorId] = useState<number | null>(null);

        const {
            changeActiveIndex,
            regenerateTitle,
            clearEditings,
            changeShowHiddenValuesStatus,
            changeEditingModalOpeningStatus,
        } = useActions();

        const currentTableItem = useMemo<TableTabItem | undefined>(() => {
            return tabs.find((item) => item.Id === activeIndex?.rate);
        }, [activeIndex, tabs]);

        useEffect(() => {
            getCurrentTabs({ type }).then((data) => {
                setTabs(data);
                changeActiveIndex(
                    data.length
                        ? {
                              rate: data[0].Id,
                              table: data[0].GeneralReportTableId,
                              name: data[0].Name,
                          }
                        : null
                );
            }).finally(() => setIsLoadingTabs(false));
            regenerateTitle(renameFunction);
            return () => {
                changeActiveIndex(null);
                clearEditings();
                changeShowHiddenValuesStatus(false);
            };
        }, []);

        const [isEditingModalOpen, setIsEditingModalOpen] =
            useState<boolean>(false);

        const onCreateItem = () => {
            if (Object.keys(promises).length) {
                setIsEditingModalOpen(true);
            } else {
                changeEditingModalOpeningStatus("creating");
            }
        };
        const cancelModal = () => {
            changeEditingModalOpeningStatus(false);
            setNewItem(null);
        };

        const [isBottomTableShowed, setIsBottomTableShowed] = useState(true);

        const toggleBottomShowed = (val?: boolean) => {
            setIsBottomTableShowed((prev) => {
                const finalValue = val !== undefined ? val : !prev;
                if (finalValue) {
                    const elem = document.querySelector(
                        '[data-type="summary"]'
                    );
                    if (elem) {
                        elem.scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                            inline: "start",
                        });
                    }
                }

                return finalValue;
            });
        };

        return (
            <Flex vertical gap={10}>
                <Flex align="flex-start" justify="space-between" gap={12}>
                    <TabsList
                        items={tabs}
                        type={type}
                        isLoadingTabs={isLoadingTabs}
                        createTabTitle={newTabText}
                        onCreateItem={onCreateItem}
                        deleteItem={removeTab}
                    />

                    <Archive
                        type={type}
                        tabs={tabs}
                        // filters={{}}
                        createTab={createNewTab}
                    />
                </Flex>

                <Modal
                    open={isEditingModalOpen}
                    title="Внимание"
                    centered
                    width={800}
                    onCancel={() => setIsEditingModalOpen(false)}
                    onClose={() => setIsEditingModalOpen(false)}
                    footer={() => <></>}
                >
                    <strong>
                        В ваших текущих отчетах имеются несохраненные изменения.
                        Примите их или сбросьте их перед тем, как создать новый
                        отчет
                    </strong>
                </Modal>

                <TableSearcher
                    isBottomShowed={isBottomTableShowed}
                    toggleBottomShowed={toggleBottomShowed}
                    setTrigger={setTrigger}
                    updateTabs={setTabs}
                />

                <Component
                    cancelModal={cancelModal}
                    createNewTab={createNewTab}
                    trigger={trigger}
                    errorId={errorId}
                    isBottomTableShowed={isBottomTableShowed}
                    cleanErrorId={() => setErrorId(null)}
                    currentTableItem={currentTableItem}
                    updateNewItem={updateNewItem}
                />
            </Flex>
        );
    };
};
