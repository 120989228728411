import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import styles from "./styles.module.css";
import classNames from "classnames";
import { MoveLeft, MoveRight } from "lucide-react";

interface Props {
    children: React.ReactElement;
    step?: number;
    marginRight?: number;
    hideArrows?: boolean;
    baseValues?: number;
}

const Slider = forwardRef((props: Props, ref) => {
    const { children, step = 200, marginRight, baseValues } = props;

    const sliderRef = useRef<HTMLElement | null>(null);
    const containerRef = useRef(null);

    const checkScrollStatus = (item: HTMLElement) => {
        if (item.scrollLeft + item.clientWidth >= item.scrollWidth) {
            setScrollStatus("right");
        } else if (item.scrollLeft <= 0) {
            setScrollStatus("left");
        } else {
            setScrollStatus("none");
        }
    };

    const scrollLeft = () => {
        if (!sliderRef.current) return;
        sliderRef.current.scrollLeft -= step;
        checkScrollStatus(sliderRef.current);
    };

    const scrollRight = () => {
        if (!sliderRef.current) return;
        sliderRef.current.scrollLeft += step;
        checkScrollStatus(sliderRef.current);
    };

    useEffect(() => {
        if (sliderRef.current) {
            const elem = sliderRef.current as HTMLElement;
            const rect = elem.getBoundingClientRect();
            const startRight = rect.x;

            const width = document.body.clientWidth - 100 - (marginRight ?? 0);
            elem.style.maxWidth =
                Math.floor((width - startRight) / step) * step + "px";
            elem.style.overflowX = "scroll";
            setNeedScroll(true);
            elem.classList.add("no-scroll");
            if (baseValues) {
                setNeedScroll(
                    baseValues > Math.floor((width - startRight) / step)
                );
            }
            checkScrollStatus(sliderRef.current);
        }
    }, [sliderRef]);

    const [needScroll, setNeedScroll] = useState(false);

    const [scrollStatus, setScrollStatus] = useState<"right" | "left" | "none">(
        "left"
    );

    useImperativeHandle(
        ref,
        () => {
            return {
                calculateWidth() {
                    if (sliderRef.current) {
                        const elem = sliderRef.current as HTMLElement;
                        const rect = elem.getBoundingClientRect();
                        const startRight = rect.x;

                        const width =
                            document.body.clientWidth -
                            100 -
                            (marginRight ?? 0);
                        elem.style.maxWidth =
                            Math.floor((width - startRight) / step) * step +
                            "px";
                        elem.style.overflowX = "scroll";
                        setNeedScroll(
                            elem.children.length >
                                Math.floor((width - startRight) / step)
                        );
                        elem.classList.add("no-scroll");
                        checkScrollStatus(sliderRef.current);
                    }
                },
            };
        },
        []
    );

    return (
        <div
            ref={containerRef}
            className={classNames(styles.slider, "no-scroll")}
        >
            {needScroll && (
                <button
                    onClick={scrollLeft}
                    className={classNames(styles.arrow, {
                        [styles.deadlock]: scrollStatus === "left",
                    })}
                >
                    <MoveLeft fontSize={18} color="#898989" />
                </button>
            )}
            {React.cloneElement(children, { ref: sliderRef })}
            {needScroll && (
                <button
                    onClick={scrollRight}
                    className={classNames(styles.arrow, {
                        [styles.deadlock]: scrollStatus === "right",
                    })}
                >
                    <MoveRight fontSize={18} color="#898989" />
                </button>
            )}
        </div>
    );
});

export default Slider;
