import { useMemo } from "react";
import {
    CreatingForm,
    ICreatingFormResponse,
    resourceDictionary,
} from "../types";
import { useAppSelector } from "app/hooks";

type ReturnType = CreatingForm[];

export const useCreatingForm = (): ReturnType => {
    const { resources: data } = useAppSelector((state) => state.Excel);

    const formGenerator = useMemo<CreatingForm[]>(() => {
        if (!data.length) return [];
        return data.reduce<CreatingForm[]>(
            (acc: CreatingForm[], cur: ICreatingFormResponse) => {
                const current = acc.find((el) => el.value === cur.AxisName);
                if (!current)
                    return [
                        ...acc,
                        {
                            value: cur.AxisName,
                            title: cur.AxisName,
                            items: [
                                {
                                    name: String(cur.EnergySourceId),
                                    title: cur.EnergySourceName,
                                    items: [
                                        {
                                            items: cur.AvailableModes.map(
                                                (mode) => ({
                                                    key: resourceDictionary[
                                                        mode
                                                    ].short,
                                                    value: mode,
                                                })
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },
                    ];
                return acc.map((el) =>
                    el.value === current.value
                        ? {
                              ...el,
                              items: [
                                  ...el.items,
                                  {
                                      name: String(cur.EnergySourceId),
                                      title: cur.EnergySourceName,
                                      items: [
                                          {
                                              items: cur.AvailableModes.map(
                                                  (mode) => ({
                                                      key: resourceDictionary[
                                                          mode
                                                      ].short,
                                                      value: mode,
                                                  })
                                              ),
                                          },
                                      ],
                                  },
                              ],
                          }
                        : el
                );
            },
            []
        );
    }, [data]);

    return formGenerator;
};
