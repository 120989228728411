import { FC, useEffect, useRef, useState } from "react";
import {
    IPeriodModifier,
    IEventPosition,
    IPointEvent,
} from "services/pointEvents/pointEventsSlice";

import { TransitionPeriods, SimplePeriods, PeriodHeader } from "../";
import styles from "./styles.module.css";
import { DisabledSpace } from "../../../../components/UI";
import { EventPeriodType, IEventType } from "entities/Types/types";
import RedButton from "shared/components/RedButton";
import { parseModifierTransition } from "components/EventModal/utils";
import { usePointEventsContext } from "entities/PointEvents/context/PointEventsContext";

type Props = {
    currentType: IEventType | null;
    saveOrUpdateEvent: (periods: IPeriodModifier[]) => void;
    beginDate: string;
    currentEvent?: IPointEvent;
    role: "lineman" | "acceptor";
    mainEventType: EventPeriodType;
    availablePositions: IEventPosition[];
};

const PeriodsFabric: FC<Props> = (props) => {
    const {
        beginDate,
        mainEventType,
        currentType,
        currentEvent,
        saveOrUpdateEvent,
        availablePositions,
        role,
    } = props;

    const isTransition = mainEventType === "transition";
    const { currentObject } = usePointEventsContext();

    const [periodslav, setPeriodslav] = useState<IPeriodModifier[]>(() => {
        if (!currentEvent)
            return [
                {
                    BeginDate: "",
                    EndDate: "",
                    Value: [0],
                    Position: [],
                    CellMappingId: [0, 0],
                    id: new Date().getTime(),
                },
            ];
           
        if (isTransition) return parseModifierTransition(currentEvent.ModifierData)    
        return currentEvent.ModifierData.map((el, index) => {
            return {
                BeginDate: el.BeginDate,
                EndDate: el.EndDate,
                Value: [el.Value],
                Position: [el.Position],
                CellMappingId: Array.isArray(el.CellMappingId)
                    ? [...el.CellMappingId, 0]
                    : [el.CellMappingId, 0],
                id: +`${new Date().getTime()}${index}`,
            };
        });
    });

    const updatePeriod = (idx: number, value: Partial<IPeriodModifier>) => {
        setPeriodslav((prev) =>
            prev.map((el, id) => {
                return id === idx ? { ...el, ...value } : el;
            })
        );
    };

    const addPeriod = () => {
        setPeriodslav((prev) =>
            prev.concat({
                BeginDate: "",
                EndDate: "",
                Value: [0],
                Position: availablePositions.length
                    ? [
                          availablePositions[0].Position,
                          availablePositions[
                              availablePositions.length > 1 ? 1 : 0
                          ].Position,
                      ]
                    : [],
                CellMappingId: [0, 0],
                id: new Date().getMilliseconds(),
            })
        );
    };

    const removePeriod = (id: number) => {
        setPeriodslav((prev) => prev.filter((_, i) => i !== id));
    };

    const firstCallStatus = useRef<boolean>(currentEvent ? true : false);
    // первый вызов нужен, если это создание, не нужен - если редактирование
    useEffect(() => {
        if (firstCallStatus.current) {
            firstCallStatus.current = false;
            return;
        }
        setPeriodslav((prev) => {
            return prev.map((el) => {
                if (availablePositions.length === 0) {
                    return {
                        BeginDate: "",
                        EndDate: "",
                        Value: [0],
                        Position: [],
                        CellMappingId: [0, 0],
                        id: el.id,
                    };
                }
                return {
                    ...el,
                    Position: [
                        availablePositions[0].Position,
                        availablePositions[
                            availablePositions.length > 1 ? 1 : 0
                        ].Position,
                    ],
                };
            });
        });
    }, [availablePositions]);

    return (
        <DisabledSpace isActive={!currentObject} zIndex={3}>
            <div className={styles["periods-container"]}>
                {periodslav.map((period, index) => (
                    <div
                        className="period-container"
                        key={"period-" + (period.id ?? index)}
                    >
                        <PeriodHeader
                            id={index}
                            updatePeriod={(value: Partial<IPeriodModifier>) =>
                                updatePeriod(index, value)
                            }
                            period={period}
                            availablePositions={availablePositions}
                            periodsCount={periodslav.length}
                            removePeriod={removePeriod}
                            mainEventType={mainEventType}
                        />

                        {isTransition ? (
                            <TransitionPeriods
                                period={period}
                                setPeriods={setPeriodslav}
                                index={index}
                                beginDate={beginDate}
                            />
                        ) : (
                            <SimplePeriods
                                period={period}
                                setPeriods={setPeriodslav}
                                index={index}
                                beginDate={beginDate}
                                currentType={currentType}
                            />
                        )}
                    </div>
                ))}
                {role === "lineman" && (
                    <>
                        <div>
                            <RedButton
                                disabled={!currentObject}
                                onClick={addPeriod}
                            >
                                + Добавить период
                            </RedButton>
                        </div>

                        <RedButton
                            onClick={() => saveOrUpdateEvent(periodslav)}
                        >
                            Сохранить
                        </RedButton>
                    </>
                )}
            </div>
        </DisabledSpace>
    );
};

export default PeriodsFabric;
