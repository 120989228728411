import { Button, Tooltip } from "antd";
import { CheckOutlined, StopOutlined } from "@ant-design/icons";
import { useActions, useAppSelector } from "app/hooks";
import { updateEditableCells } from "entities/ExcelTable/api";
import { callSuccess } from "shared/helpers";
import { FC, useMemo, useRef, useState } from "react";
import styles from "./styles.module.css";
import { useEffectSkipFirstRender } from "hooks";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { TableTabItem } from "entities/ExcelTable/types";

type Props = {
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    updateTabs: React.Dispatch<React.SetStateAction<TableTabItem[]>>;
};

enum EDIT_URLS {
    guides = "/ReportTable/cells",
    balance = "/ReportTemplateCell/many",
}

enum EDIT_NAMES {
    guides = "RateTemplateId",
    balance = "ReportTemplateId",
}

const ActionButtons: FC<Props> = ({ setTrigger, updateTabs }) => {
    const { promises, activeIndex, isLoadingState } = useAppSelector(
        (state) => state.Excel
    );
    const { clearEditings, changeActiveIndexTemplateId, triggerLoader } =
        useActions();
    const location = useLocation();

    const isButtonDisabled = Object.keys(promises).length === 0;

    async function onAcceptPromises() {
        const isGuides = location.pathname.includes("guides");
        triggerLoader(true);
        const config = {
            url: isGuides ? EDIT_URLS.guides : EDIT_URLS.balance,
            templateName: isGuides ? EDIT_NAMES.guides : EDIT_NAMES.balance,
        };
        const isOk = await updateEditableCells(
            promises,
            config.url,
            config.templateName
        );
        if (isOk) {
            clearEditings();
            callSuccess("Успешно!");
            if (typeof isOk === "number") {
                updateTabs((prev) =>
                    prev.map((el) =>
                        el.Id === activeIndex?.rate
                            ? { ...el, tableId: isOk }
                            : el
                    )
                );
                changeActiveIndexTemplateId(isOk);
            } else {
                setTrigger((prev) => !prev);
            }
        }
        triggerLoader(false);
    }

    const timer = useRef<ReturnType<typeof setInterval> | null>(null);
    const [animtate, setAnimate] = useState<boolean>(false);

    const isShowed = useMemo<boolean>(() => {
        return !!Object.keys(promises).length;
    }, [promises]);

    useEffectSkipFirstRender(() => {
        if (!isShowed) {
            timer.current && clearInterval(timer.current);
            timer.current = null;
        } else {
            setAnimate(true);
            timer.current = setInterval(() => {
                setAnimate((prev) => !prev);
            }, 15000);
        }
    }, isShowed);

    return (
        <div className={styles["action-row"]}>
            {isShowed && (
                <>
                    <Tooltip title="Принять изменения">
                        <Button
                            shape="circle"
                            className={classNames({ [styles.first]: animtate })}
                            disabled={isButtonDisabled || isLoadingState}
                            onClick={onAcceptPromises}
                            icon={<CheckOutlined style={{ color: "green" }} />}
                        />
                    </Tooltip>
                    <Tooltip title="Отменить изменения">
                        <Button
                            shape="circle"
                            className={classNames({
                                [styles.second]: animtate,
                            })}
                            disabled={isButtonDisabled || isLoadingState}
                            onClick={(e) => clearEditings()}
                            icon={<StopOutlined style={{ color: "red" }} />}
                        />
                    </Tooltip>
                </>
            )}
        </div>
    );
};

export default ActionButtons;
