import { useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ITableLink } from "entities/ExcelTable/types";
import { Flex } from "antd";
import { ModuleBlock } from "entities/ExcelTable/components";
import { iconDictionary } from "entities/ExcelTable/helpers/constants";
import styles from "./styles.module.css";

const linkTree: ITableLink[] = [
    {
        link: "balance",
        name: "Энергобаланс",
        Icon: iconDictionary.balance,
        children: [
            {
                link: "balance/heat",
                name: "Тепло",
            },
            { link: "balance/fuel", name: "Топливо" },
            { link: "balance/electric", name: "Электроэнергия" },
            { link: "balance/compressed", name: "Сжатый воздух" },
        ],
    },
    {
        link: "guides",
        name: "Справочники",
        Icon: iconDictionary.guides,
        children: [
            {
                link: "guides/templates",
                name: "Шаблоны энергоресурсов",
            },
            { link: "guides/view", name: "Нормы" },
            { link: "guides/files", name: "Загрузка значений файлов Сырья, ТЭР" },
            { link: "guides/renovation", name: "Ремонтные работы" },
        ],
    },
];

const onOnlyIsEnergyRegExp = /is-energy$/;

const ISMainPage = () => {
    const location = useLocation();

    // useEffect(() => {
    //     [3,4].forEach(el => {
    //         deleteRate(el)
    //     })

    // },[])

    const actual = useMemo<ITableLink[] | undefined>(() => {
        if (onOnlyIsEnergyRegExp.test(location.pathname)) return linkTree;
        const actual = linkTree.find((el) =>
            location.pathname.includes(el.link)
        );
        if (actual) return [actual];
        return undefined;
    }, [location.pathname]);

    if (actual === undefined) return <Navigate to={"/page-404"} replace />;
    // return <LinksMainList links={actual} />;
    return (
        <Flex vertical gap={20}>
            {actual.map((el) => (
                <ModuleBlock
                    title={el.name}
                    key={el.name}
                    links={el.children}
                    className={styles[el.link]}
                    Icon={el.Icon}
                />
            ))}
        </Flex>
    );
};

export default ISMainPage;
